const removeTextByRegex = (value, regex=/[^a-zA-ZÀ-ÿ@\s]+/) => {
  const newValue = value.replace(regex, "");
  return newValue
}

const removeNullOrEmptyFromArray = (array) => {
  return array.filter(x=>x).join(" ");
}

export default {
  removeTextByRegex: removeTextByRegex,
  removeNullOrEmptyFromArray
}