<template>
  <div>
    <!-- Title -->
    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="">
        </div>
      </div>
      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Persona Que Refiere</label>
        </div>
      </div>
    </div>

    <!-- Form -->
    <div class="md:ml-10">
      <div class="mt-10">
        <ChooseButton 
          v-model="v$.referredPerson.wasReferred.$model"
          :buttons="buttonsReferredPerson"
          v-on:choose-button="getValueChooseButton($event)"
          :selectFirstButton="false"
          label="¿El socio que se está inscribiendo fue referido por algún otro socio de FONCABSA?" />

          <div v-for="error of v$.referredPerson.wasReferred.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>
      </div>

      <div class="grid md:grid-cols-2 grid-cols-1 gap-4 mt-10" v-if="referredPerson.wasReferred == 'true'">
        <div>
          <SearchSelect 
            :options="clients" 
            label="Buscar persona que refiere:"
            :classInput="v$.referredPerson.client.$error ? 'input-invalid-form shadow-md border-2 rounded-lg px-2 py-2 w-full mt-2 font-light none' : 'shadow-md border-2 rounded-lg w-full px-2 py-2 mt-2 font-light none'"
            v-model.trim="v$.referredPerson.client.$model"
            v-on:event-search-select="change($event)" />
            
            <div v-for="error of v$.referredPerson.client.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
        </div>
        <div>
          <p class="label-input">Persona que refiere: </p>
          <p class="label-value font-normal text-md" id="label_Person_referred">{{referredPerson.client == null ? '' : referredPerson.client.text}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import ChooseButton from '@/components/Forms/Buttons/ChooseButton.vue'
import SearchSelect from '@/components/Forms/Selects/SearchSelect.vue'
import Client from '@/classes/Client'

import useVuelidate from '@vuelidate/core';
import { requiredIf, helpers } from '@vuelidate/validators';
import("@/assets/css/referredperson.css");

export default {
  name: 'ReferredPerson',

  setup () {
    let referredPerson = ref({
        wasReferred: null,
        client: {},
    });

    let clientObjectClass = ref(null);

    const buttonsReferredPerson = ref([
      { id: 'button_yes', value: 'true', text: 'Si' },
      { id: 'button_no', value: 'false', text: 'No' }
    ]);

    let clients = ref([]);

    return { 
      v$: useVuelidate(),
      referredPerson,
      clientObjectClass,
      buttonsReferredPerson,
      clients
    };
  },

  validations() {
    return {
      referredPerson: {
        client: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function(){
            return this.referredPerson.wasReferred == 'true' ? true : false;
          })),
        },
        wasReferred: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function(value){
            return this.referredPerson.wasReferred == null || this.referredPerson.wasReferred == "" || typeof this.referredPerson.wasReferred == 'undefined' ? true : false
          })),
        }
      }
    }
  },
  methods: {
    change(value){
      this.referredPerson.client = value;
    },
  
    getValueChooseButton(event) {
      this.referredPerson.client = {},
      this.referredPerson.wasReferred = event;
    },

    async getClients() {
      try {
        const response = await this.clientObjectClass.getClients(sessionStorage.getItem("login"), '');
        const clientsFiltered = response.data.response.list_persons.filter(p => p.user != undefined)
        
        this.clients = clientsFiltered.map((r,i) => {
          var textAndValue = ""

          if(r.adress == undefined){
            textAndValue = `${r.full_name}`
          }else{
            textAndValue = `${r.full_name}, ${r.adress.municipality}, ${r.adress.state}`
          }
          return ({value: r.user.id, text: textAndValue})
        })
      } catch (error) {
        console.error(error);
      }
    },
    touch(){
      this.v$.$validate();
      return this.v$.$error;
    },
  },
  
  beforeMount() {
    this.clientObjectClass = new Client({birthday:"", name:"", middle_name:"", paternal_name:"", maternal_name:"", rfc:"", id:""});
    this.getClients();
  },
  
  watch: {
    referredPerson: {
      handler(data) {
        this.$emit('referred-person-data', data);
      },
      deep: true
    }
  },

  components: {
    ChooseButton,
    SearchSelect
  },
}
</script>