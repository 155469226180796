<template>
  <div style="overflow-x: hidden;">
    <ReferredPerson 
      v-if="rulesLoaded"
      ref="referredPersonRefs"
      v-on:referred-person-data="getReferredPersonData($event)"
      class="mt-10" />

    <IdentificationRegister 
      class="mt-10"
      ref="identificationRegisterRefs"
      v-on:identification-register-data="getIdentificationRegisterData($event)" />
  
    <PersonalInfo 
      v-if="rulesLoaded"
      ref="personalInfoRefs"
      :rulesValidations="rulesValidations"
      :rulesLoaded="rulesLoaded"
      :spouseRfc="spouseRfc"
      v-on:personal-info-data="getPersonalInfoData($event)"
      class="mt-10" />
    
    <BirthPlace 
      v-if="rulesLoaded"
      ref="birthPlaceRefs"
      :rulesValidations="rulesValidations" 
      :rulesLoaded="rulesLoaded"
      v-on:birth-place-data="getBirthPlaceData($event)"
      class="mt-10" />
    <Address 
      v-if="rulesLoaded"
      ref="addressUserRefs"
      :rulesValidations="rulesValidations"
      :rulesLoaded="rulesLoaded"
      class="mt-10" v-on:adress_attributes="getAddress($event)" />
    <AssistedContact 
      v-if="rulesLoaded"
      ref="contactUserRefs"
      :rulesValidations="rulesValidations"
      :rulesLoaded="rulesLoaded"
      class="mt-10" v-on:contact_info_attributes="getAataContactInfo($event)"/>
    <HousingInfo 
      v-if="rulesLoaded"
      ref="housingInfoRefs"
      :rulesValidations="rulesValidations"
      :rulesLoaded="rulesLoaded"
      v-on:housing-info-data="getHousingInfoData($event)"
      class="mt-10" />
    <ScholarshipInformation 
      v-if="rulesLoaded"
      ref="scholarshipInfoInfoRefs"
      v-on:scholarship-information-data="getScholarshipInfoData($event)"
      :rulesValidations="rulesValidations" 
      :rulesLoaded="rulesLoaded" 
      class="mt-10" />
    <CivilStatus
      v-if="rulesLoaded"
      ref="civilStatusRefs"
      :rulesValidations="rulesValidations"
      :rulesLoaded="rulesLoaded"
      :memberRfc="memberRfc"
      v-on:civil-status-data="getCivilStatusData($event)"
      class="my-10" /> 
    
    <div class="flex mt-20">
      <input type="button" class="cursor-pointer shadow-md rounded-lg w-full md:w-1/4 mx-auto px-3 py-3 mt-2 btn-next" @click="next()" value="Siguiente">
    </div>
  </div>
  
</template>

<script>

import { ref, onMounted, onBeforeMount} from 'vue';
import Swal from "sweetalert2";

import User from '@/classes/User';
import Referral from '@/classes/Referral';
import ReferredPerson from '@/components/Forms/AssistedEnrollment/ReferredPerson';
import IdentificationRegister from '@/components/Forms/AssistedEnrollment/IdentificationRegister';
import BirthPlace from '@/components/Forms/AssistedEnrollment/BirthPlace.vue';
import Address from "@/components/Forms/AssistedEnrollment/Address.vue";
import HousingInfo from '@/components/Forms/AssistedEnrollment/HousingInfo';
import PersonalInfo from '@/components/Forms/AssistedEnrollment/PersonalInfo';
import ScholarshipInformation from '@/components/Forms/AssistedEnrollment/ScholarshipInformation';
import CivilStatus from '@/components/Forms/AssistedEnrollment/CivilStatus';
import AssistedContact from '@/components/Forms/AssistedEnrollment/AssistedContact.vue';

import validations from '@/helpers/validations';
import SwalAlertHelper from '@/helpers/swal-alert';

import AdmissionRequest from '@/classes/AdmissionRequest';
import { useRouter } from 'vue-router';

import('@/assets/css/referredperson.css');
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {

  name: 'AssistedEnrollmentStep1Component',

  setup() {
    
    let client = ref('');
    let rulesValidations = ref({});
    let rulesLoaded = ref(false);
    let admissionRequestObject = ref(null);
    let referraltObjectClass = ref(null);
    let user_id = ref(0);
    let accountType = ref('');
    let identificationFiles = ref([]);
    let memberRfc = ref('');
    let spouseRfc = ref('');
    let token_auth=ref("");

    const identificationRegisterRefs = ref(null);
    const referredPersonRefs = ref(null);
    const addressUserRefs = ref(null);
    const birthPlaceRefs = ref(null);
    const housingInfoRefs = ref(null);
    const contactUserRefs = ref(null);
    const scholarshipInfoInfoRefs = ref(null);
    const civilStatusRefs = ref(null);
    const personalInfoRefs = ref(null);
    const router = useRouter();
    
    onBeforeMount(() => {
      getValidationsRules();
      loadInfo();
    });

    onMounted(() => {
      token_auth.value=sessionStorage.getItem('login');
      admissionRequestObject = new AdmissionRequest();

      referraltObjectClass = new Referral();
      sessionStorage.removeItem('admissionRequest');
      sessionStorage.removeItem('identification-files');
    });

    async function getValidationsRules() {
      const response = await validations.getStructure();
      rulesValidations.value = response.data.validations;
      rulesLoaded.value = true;
    };

    function getBirthPlaceData(data) {
      admissionRequestObject.data.country_birth = (data.country == undefined || data.country.toString() == "")? "" : data.country.toUpperCase();
      admissionRequestObject.data.state_birth = (data.state == undefined || data.state.toString() == "")? "" : data.state.toUpperCase();
      admissionRequestObject.data.municipality_birth = (data.municipality == undefined || data.municipality.toString() == "")? "" : data.municipality.toUpperCase();
      admissionRequestObject.data.city_birth = (data.city == undefined || data.city.toString() == "")? "" : data.city.toUpperCase();
    };

    function getHousingInfoData(data) {
      admissionRequestObject.data.residence_start = data.startOfResidency;
      admissionRequestObject.data.residence_status = data.type.toUpperCase();
      admissionRequestObject.data.adress_attributes.residence_start = data.startOfResidency;
      admissionRequestObject.data.adress_attributes.residence_status = data.type.toUpperCase();
    };

    function getScholarshipInfoData(data) {
      admissionRequestObject.data.education_level = data.toUpperCase();
    };

    function getCivilStatusData(data) {
      spouseRfc.value = data.rfc.toUpperCase();
      admissionRequestObject.data.marital_status = data.status.toUpperCase();
      admissionRequestObject.data.regime = data.regimeOfGoods.toUpperCase();
      admissionRequestObject.data.spouse_attributes.rfc = data.rfc.toUpperCase();
      admissionRequestObject.data.spouse_attributes.name = data.firstName.toUpperCase();
      admissionRequestObject.data.spouse_attributes.middle_name = data.secondName.toUpperCase();
      admissionRequestObject.data.spouse_attributes.paternal_name = data.paternalName.toUpperCase();
      admissionRequestObject.data.spouse_attributes.maternal_name = data.maternalName.toUpperCase();
      admissionRequestObject.data.spouse_attributes.gender = data.gender;
    };

    function getReferredPersonData(data) {
      user_id = data.client != null ? data.client.value == undefined ? "": data.client.value : "";
    };

    function getPersonalInfoData(data) {
      memberRfc.value = data.rfc.toUpperCase();
      admissionRequestObject.data.rfc = data.rfc.toUpperCase();
      admissionRequestObject.data.curp = data.curp.toUpperCase();
      admissionRequestObject.data.birthday = data.dateBirth;
      admissionRequestObject.data.paternal_name = data.paternalName.toUpperCase();
      admissionRequestObject.data.maternal_name = data.maternalName.toUpperCase();
      admissionRequestObject.data.name = data.firstName.toUpperCase();
      admissionRequestObject.data.middle_name = data.secondName.toUpperCase();
      admissionRequestObject.data.gender = data.gender;
    };

    function getAddress(data){

      admissionRequestObject.data.adress_attributes.street = data.street.toUpperCase();
      admissionRequestObject.data.adress_attributes.suburb = data.suburb.toUpperCase();
      admissionRequestObject.data.adress_attributes.city = (data.city == "" || data.city == undefined) ? '-----' : data.city;
      admissionRequestObject.data.adress_attributes.state = data.state;
      admissionRequestObject.data.adress_attributes.country = data.country.toUpperCase();
      admissionRequestObject.data.adress_attributes.ext_number = data.ext_number;
      admissionRequestObject.data.adress_attributes.int_number = data.int_number;

      admissionRequestObject.data.adress_attributes.postal_code = data.postal_code.toUpperCase();
      admissionRequestObject.data.adress_attributes.municipality = data.municipality;
      admissionRequestObject.data.adress_attributes.between_street_one = (data.between_street_one == undefined || data.between_street_one.toString() == "")? "-----" : data.between_street_one.toUpperCase();
      admissionRequestObject.data.adress_attributes.between_street_two = (data.between_street_two == undefined || data.between_street_two.toString() == "")? "-----" : data.between_street_two.toUpperCase();
      admissionRequestObject.data.adress_attributes.status = "activo";

      admissionRequestObject.data.street = data.street.toUpperCase();
      admissionRequestObject.data.suburb = data.suburb.toUpperCase();
      admissionRequestObject.data.city = data.city;
      admissionRequestObject.data.state = data.state;
      admissionRequestObject.data.country = data.country.toUpperCase();
      admissionRequestObject.data.ext_number = data.ext_number.toUpperCase();
      admissionRequestObject.data.int_number = data.int_number.toUpperCase();

      admissionRequestObject.data.postal_code = data.postal_code.toString();
      admissionRequestObject.data.municipality = data.municipality;
      admissionRequestObject.data.between_street_one = data.between_street_one.toUpperCase();
      admissionRequestObject.data.between_street_two = data.between_street_two.toUpperCase();

    };

    function getAataContactInfo(value){
      let contact_info_attributes=[];
      let Objet =new Object({
         phone: value.phone,
         cellPhone: value.cellPhone,
         email: value.email,
     })

      if(Objet.phone!=null && Objet.phone!="" && Objet.phone.length==12){
          Objet.phone = Objet.phone.replace(/[\s-]+/g, '');
          let contactInfoAttributes=new Object({
            value: Objet.phone,
            status: "activo",
            extension: "",
            contact_type: "telefono particular"
          });
          contact_info_attributes.push(contactInfoAttributes);
      }

      if(Objet.cellPhone!=null && Objet.cellPhone!="" && Objet.cellPhone.length==12){
          Objet.cellPhone = Objet.cellPhone.replace(/[\s-]+/g, '');
          let contactInfoAttributes=new Object({
            value: Objet.cellPhone,
            status: "activo",
            extension: "",
            contact_type: "celular"
          });
          contact_info_attributes.push(contactInfoAttributes);
      }

      if(Objet.email!="" && Objet.email!=null){
        let contactInfoAttributes=new Object({
            value: Objet.email,
            status: "activo",
            extension: "",
            contact_type: "correo"
          });
          contact_info_attributes.push(contactInfoAttributes);
       }
        
      admissionRequestObject.data.contact_info_attributes=Object.assign({}, contact_info_attributes);

    };

    function getIdentificationRegisterData(data) {

      let sessionStorageIdentification = sessionStorage.getItem('identification-files');
      sessionStorageIdentification = data.files.files;
      identificationFiles.value = data.files.files;
      sessionStorage.setItem('identification-files', JSON.stringify(sessionStorageIdentification));
      
      admissionRequestObject.data.identification_type = data.document_type.toString().toUpperCase();
      admissionRequestObject.data.identification_number = data.identification_number == "" ? data.passport_number.toString().toUpperCase() :  data.identification_number
    };

    async function getReferences() {
      try {
        const response = await referraltObjectClass.getSent(sessionStorage.getItem("login"),user_id);
        const filter =  response.data.response.data.filter(r => r.email.toUpperCase() == admissionRequestObject.data.contact_info_attributes[1].value.toUpperCase());

        if (filter.length > 0) {
          admissionRequestObject.data.reference = filter[0].reference_code;
          admissionRequestObject.data.referal_id = filter[0].id;
          admissionRequestObject.data.invited_client_id = "";
        } else {
          admissionRequestObject.data.invited_client_id = user_id;
        }
      } catch (error) {
        
      }
    };

    function convertListToArray(list) {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    };

    function identificationFilesComplete() {
      console.log("🚀 ~ file: AssistedEnrollmentStep1.vue:314 ~ identificationFilesComplete ~ identificationFiles.value", identificationFiles.value)
      if(identificationFiles.value.length > 0) {
        
        return true;
      }
      return false;
    };

    function loadInfo() {
      var auth = getTokenDecoden();
      accountType.value = auth.obj["role"];
    };

    async function  next(){
  
      const referredPersonRef = !referredPersonRefs.value.touch();
      const identificationRegisterRef = !identificationRegisterRefs.value.touch();
      const personalInfoRef = !personalInfoRefs.value.touch();
      const birthPlaceRef = !birthPlaceRefs.value.touch();
      const addressUserRef = !addressUserRefs.value.touch();
      const contactUserRef = !contactUserRefs.value.touch();
      const housingInfoRef = !housingInfoRefs.value.touch();
      const scholarshipInfoInfoRef = !scholarshipInfoInfoRefs.value.touch();
      const civilStatusRef = !civilStatusRefs.value.touch(); 
      let account = "";

      if(civilStatusRef && birthPlaceRef && addressUserRef && contactUserRef && referredPersonRef
        && personalInfoRef && housingInfoRef && scholarshipInfoInfoRef && identificationRegisterRef) {
        
        const identificationComplete = identificationFilesComplete();

        if (!identificationComplete) {
          Swal.fire({
            title: "Aviso",
            text: "Favor de adjuntar la Identificación Oficial",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          })
          SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_identification_require');
          SwalAlertHelper.setIdAttributeToButtons('button_identification_require_ok', '');
          return;
        }
        console.log("user_id", user_id);
        if (user_id != "") {
          await getReferences();
        }

        console.log(accountType.value)
        switch (accountType.value) {
          case "administrator":
            account = "admin";
            break;
          case "member":
            account = "cuenta";
            break;
          case "member_analyst":
            account = "analyst";
            break;
          case "cabsa_member":
            account = "cuenta";
            break;
          case "supervisor":
            account = "supervisor";
            break;
          case "loan_analyst":
            account = "analyst";
            break;
          case "enroller": 
            account="adviser";
          break;
        }
        
        sessionStorage.setItem('admissionRequest',  JSON.stringify(admissionRequestObject.data));
        router.push({path:`/${account}/captura-asistida/seccion/dos`})
        // if(accountType == "supervisor"){
        //   router.push({ name: 'CapturaAsistidaSeccionDosSupervisor' });
        // }else if(accountType == "loan_analyst" || accountType == "member_analyst")
        //   router.push({ name: 'CapturaAsistidaSeccionDosAnalyst' });
        // else
        //   router.push({ name: 'CapturaAsistidaSeccionDos' });
      }else{
        console.log("Componente invalido!");
      }
    };

    return {
      client,
      rulesValidations,
      rulesLoaded,
      admissionRequestObject,
      referraltObjectClass,
      user_id,
      accountType,
      identificationFiles,
      getBirthPlaceData,
      getHousingInfoData,
      getScholarshipInfoData,
      getCivilStatusData,
      getReferredPersonData,
      getPersonalInfoData,
      getAddress,
      getAataContactInfo,
      getIdentificationRegisterData,
      getReferences,
      convertListToArray,
      identificationFilesComplete,
      loadInfo,
      next,
      referredPersonRefs,
      contactUserRefs,
      addressUserRefs,
      birthPlaceRefs,
      housingInfoRefs,
      scholarshipInfoInfoRefs,
      civilStatusRefs,
      referredPersonRefs,
      personalInfoRefs,
      memberRfc,
      spouseRfc,
      identificationRegisterRefs
    }
  },

  components: {
    ReferredPerson,
    IdentificationRegister,
    BirthPlace,
    Address,
    PersonalInfo,
    HousingInfo,
    ScholarshipInformation,
    CivilStatus,
    AssistedContact,
  },
  
}
</script>
<style scoped>
.btn-next{
  background: #FFC001;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
</style>