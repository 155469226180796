<template>
  <div>
    <!-- <div class="dropzone" id="drop-zone">
      <div id="draggable" draggable="true" ondragstart="event.dataTransfer.setData('text/plain',null)">
        This div is draggable
      </div>
    </div> -->
    <div class="document-attachment-container" id="drop-zone">
      <div draggable="true" ondragstart="event.dataTransfer.setData('text/plain',null)">
        <div class="elements1">
          <div class="flex justify-center">
            <img src="@/assets/CapturaAsistida/carga.svg" alt="">
          </div>
          <div class="flex justify-center">
            <p class="label-document mt-6">Arrastra los archivos aquí</p>
          </div>
          <div class="flex justify-center">
            <p class="label-document mt-6">Ó</p>
          </div>
          <div class="flex justify-center">
            <div id="select-file">
              <button type="button" class="document-attachment-button" v-on:click="getFile('inputfile_official_id')">
                Seleccionar Archivo
              </button>
              <div style='height: 0px;width:0px; overflow:hidden;'>
                <input id="inputfile_official_id" type="file" :accept="documentsAllowed.join(',')"
                  @change="loadDocument($event, false)" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="elements2" style="display:none">
          <div class="flex justify-center">
            <p class="drag-here-text mt-6 elementpar">Soltar Aquí</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Document from '@/classes/Document'
import SwalAlertHelper from '@/helpers/swal-alert'
import Swal from "sweetalert2";

export default {
  data() {
    return {
      list_files: [],
      documentObjectClass: null
    }
  },
  name: 'UploadFileComponent',
  props: {
    maxSizeBytesToUpload: {
      type: Number,
      default: 16777216
    },
    maxSizeMegaBytesToUpload: {
      type: Number,
      default: 16
    },
    documentsAllowed: {
      type: Array,
      default: () => []
    },
    minFiles: {
      type: Number,
      default: () => 0
    },
    maxFiles: {
      type: Number,
      default: () => 1
    },
    minFiles: {
      type: Number,
      default: () => 0
    },
    displayStatusMessage: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.documentObjectClass = new Document();
    this.initializeDragEvents();
  },
  methods: {
    loadDocument(event, isDrag) {
      let file = [];
      if (isDrag)
        file = event.dataTransfer.files;
      else
        file = event.target.files;

      const fileAttributes = this.documentObjectClass.getDocumentAttributes(file);
      const maxDocumentsRequired = this.maxFiles;

      if (this.documentObjectClass.exceedTheMaximunSize(this.maxSizeBytesToUpload, fileAttributes.size)) {
        this.showExceedTheMaximunSizeMessage();
        return;
      }

      
      const isAllowed = this.documentObjectClass.documentExtensionIsAllowed(this.documentsAllowed, fileAttributes.completeExtension);

      if (!isAllowed) {
        this.isLoading = false;
        this.showExtensionIsNotAllowedMessage();
        return;
      }
      

      if(this.documentObjectClass.listDocuments.length > 0){
        if (!this.documentObjectClass.documentReachItsLimit(maxDocumentsRequired)) {
          if (this.documentObjectClass.fileHasAlreadyBeenUploaded(fileAttributes.name)) {
            this.isLoading = false;
            this.showFileDuplicatedMessage();
            return;
          }
        } else {
          this.showNumberFilesAllowedToAttachMessage(maxDocumentsRequired);
          return;
        }
      }

      this.documentObjectClass.serialize(file[0]).then(base64Response => {
        const newFile = this.documentObjectClass.addDocument(base64Response, fileAttributes.extension, fileAttributes.name, 'IO');
        
        if(this.displayStatusMessage){
          this.showLoadedDocumentSuccessMessage(fileAttributes.name);
        }
        
        const numberFiles = this.documentObjectClass.listDocuments.length;
        this.$emit('upload-file', {
          valid: (numberFiles >= this.minFiles),
          files: this.documentObjectClass.listDocuments,
          newFile: newFile
        });
      }).catch(error => {
        console.error(error);
      });
    },
    showFileDuplicatedMessage() {
      Swal.fire({
        title: "Aviso",
        text: "El archivo seleccionado ya ha sido adjuntado",
        icon: "error",
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_fileduplicate_deleted', '');
    },
    showExceedTheMaximunSizeMessage() {
      Swal.fire({
        title: "Aviso",
        html: `<p style="font-family:Roboto;font-size:15px;">El peso del archivo sobrepasa 
                  el limite establecido de <span style="font-weight: 700;">${this.maxSizeMegaBytesToUpload} MB</span>
                </p>`,
        icon: "error",
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_unsupported_file_size');
    },
    showNumberFilesAllowedToAttachMessage(maxDocumentsRequired) {
      Swal.fire({
        title: "Aviso",
        text: `Solo puede subir ${maxDocumentsRequired} documentos`,
        icon: "error",
      });
    },
    showExtensionIsNotAllowedMessage() {
      Swal.fire({
        title: "Aviso",
        html: `<p style="font-family:Roboto;font-size:15px;">El tipo de archivo seleccionado no está permitido<br/><br/>
                  Archivos permitidos: <br/>
                <span style="font-weight: 700;">${this.documentsAllowed.join(", ")}</span>
                </p>`,
        text: "El tipo de archivo seleccionado no está permitido",
        icon: "error",
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_unsupported_file_type');
    },
    showLoadedDocumentSuccessMessage(documentName) {
      Swal.fire({
        text: "Solicitud de prestamo guardada correctamente",
        html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">El documento: <span style="font-weight:500;">${documentName}</span> se ha adjuntado correctamente.</p>`,
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        icon: "success",
        confirmButtonColor: '#FEB72B',
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_successful_upload');
      SwalAlertHelper.setIdAttributeToButtons('button_ok_succes_file', '');
    },
    deleteFile(fileName){
      try {
        const currentFiles = this.documentObjectClass.deleteDocument(fileName); 
        Swal.fire({
          html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">Documento Eliminado Correctamente</p>`,
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
          icon: "success",
          confirmButtonColor: '#FEB72B',
        });

        const numberFiles = this.documentObjectClass.listDocuments.length;
        this.$emit('deleted-file', {
          valid: (numberFiles >= this.minFiles),
          files: currentFiles
        });
        SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_successful_deleted');
      } catch (error) {
        Swal.fire({
          html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">${error}</p>`,
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        SwalAlertHelper.setIdAttributeToButtons('error_delete', '');
      }
    },
    getFile(selector) {
      this.documentObjectClass.getFileByIdSelector(selector);
    },
    initializeDragEvents() {
      /* events fired on the draggable target */
      document.getElementById('drop-zone').addEventListener("drag", function(event) {

      }, false);

      document.getElementById('drop-zone').addEventListener("dragstart", function(event) {
        // store a ref. on the dragged elem
        dragged = event.target;
        // make it half transparent
        event.target.style.opacity = .5;
      }, false);

      document.getElementById('drop-zone').addEventListener("dragend", function(event) {
        // reset the transparency
        event.target.style.opacity = "";
      }, false);

      /* events fired on the drop targets */
      document.getElementById('drop-zone').addEventListener("dragover", function(event) {
        // prevent default to allow drop
        // var elements1 = document.getElementsByClassName('elements1');
        // let elementpar = document.getElementsByClassName('elementpar');
        // var elements2 = document.getElementsByClassName('elements2');
        var elements = document.getElementsByClassName('document-attachment-container');
        elements[0].style.border = "dashed"
        // elements[0].style.background = "#D2D2D2"
        // elements2[0].style.display = "block"
        // elementpar[0].style.color = "#000000"
        event.preventDefault();
      }, false);

      document.getElementById('drop-zone').addEventListener("dragenter", function(event) {
        // highlight potential drop target when the draggable element enters it
        // var elements2 = document.getElementsByClassName('elements2');
        // elements2[0].style.display = "none"
        var elements = document.getElementsByClassName('document-attachment-container');
        elements[0].style.border = "thick"
        // if (event.target.className == "document-attachment-container") {
        //   event.target.style.background = "purple";
        // }
      }, false);

      document.getElementById('drop-zone').addEventListener("dragleave", function(event) {
        // reset background of potential drop target when the draggable element leaves it
        // var elements2 = document.getElementsByClassName('elements2');
        // elements2[0].style.display = "none"
        var elements = document.getElementsByClassName('document-attachment-container');
        elements[0].style.border = "thick"
        event.target.style.background = "";
      }, false);

      document.getElementById('drop-zone').addEventListener("drop", (event) => {
        // prevent default action (open as link for some elements)
        event.preventDefault();
        this.loadDocument(event, true);
        // move dragged elem to the selected drop target
        var elements = document.getElementsByClassName('document-attachment-container');
        // var elements2 = document.getElementsByClassName('elements2');
        // elements2[0].style.display = "none"
        elements[0].style.border = "thick"
        elements[0].style.background = "";
      }, false);
    }
  }
}
</script>

<style>
.document-attachment-container {
  width: 100%;
  padding:45px 20px 45px 20px;
  justify-content: center;
  border-width: 1px;
  opacity: 1 !important;
  border-color: #D2D2D2 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 2px;
}
.label-document {
  color: #D2D2D2 !important;
  font-size: 15px;
}
.drag-here-text {
  color: #000 !important;
  font-size: 15px;
}
.document-attachment-button {
  width: 300px;
  height: 45px !important;
  background-color: #fff !important;
  border: 1px #D2D2D2 solid !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 2px;
  color: #D2D2D2 !important;
}
.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}
.document-attachment-button:focus {outline:0;}


#draggable {
  width: 200px;
  height: 20px;
  text-align: center;
  background: white;
}

.dropzone {
  width: 200px;
  height: 20px;
  background: blueviolet;
  margin-bottom: 10px;
  padding: 10px;
}
</style>