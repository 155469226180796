<template>
  <div>
    <div>

      <!-- Title -->
      <div class="flex">
        <div style="display:table;">
          <div style="display:table-cell;vertical-align:middle;">
            <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="">
          </div>
        </div>
        <div style="display:table;margin-left:10px;">
          <div style="display:table-cell;vertical-align:middle;">
            <label class="sub-title">Dirección</label>
          </div>
        </div>
      </div>

      <!-- Form -->
      <div class="md:ml-10 mt-5" v-if="rulesLoaded">
        <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10">
          <div>
            <label class="label-input w-full block tracking-wide mb-2" to="input_zip_code_address">Código Postal:</label>
            <input type="number" id="input_zip_code_address" name="input_zip_code_address"  
             style="color:#3B3B3B;"  
             class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor "
              v-model.trim="v$.adress_attributes.postal_code.$model"
             :class="{'invalid-form':v$.adress_attributes.postal_code.$error, 'valid-form':!v$.adress_attributes.postal_code.$invalid}"
            @blur="validateCP($event.target.value)" />

            <div v-for="error of v$.adress_attributes.postal_code.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>

          <div class="col-span-2 mt-10 sm:mt-0">
            <label class="label-input w-full block tracking-wide mb-2" to="select_neightboorhood_address">Colonia:</label>
            <select class="font-light	outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor.5" id="select_neightboorhood_address" name="select_neightboorhood_address" 
            style="color:#3B3B3B;"
             v-model.trim="v$.adress_attributes.suburb.$model"
             :class="{'invalid-form':v$.adress_attributes.suburb.$error, 'valid-form':!v$.adress_attributes.suburb.$invalid}"
            >
            <option value="" selected disabled >Seleccione una colonia</option>
            <option :value="suburb.nombre" v-for="suburb in suburbList" :key="suburb.id">{{suburb.nombre.toUpperCase()}}</option>
            </select>

            <div v-for="error of v$.adress_attributes.suburb.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 mt-10">
          <div>
            <label class="label-input w-full block tracking-wide mb-2" to="label_state_address">Estado:</label>
            <label class="label-input w-full block tracking-wide mb-2 text-3xl" id="label_state_address">{{this.adress_attributes.state.toUpperCase()}}</label>
          </div>
          <div>
            <label class="label-input w-full block tracking-wide mb-2" to="label_municipality_address">Municipio:</label>
            <label class="label-input w-full block tracking-wide mb-2 text-3xl" id="label_municipality_address">{{this.adress_attributes.municipality.toUpperCase()}}</label>
          </div>
          <div>
            <label class="label-input w-full block tracking-wide mb-2" to="label_city_address">Ciudad:</label>
            <label class="label-input w-full block tracking-wide mb-2 text-3xl" id="label_city_address" >{{this.adress_attributes.city.toUpperCase()}}</label>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-10 mt-10">
          <div class="sm:col-span-2 mt-10 sm:mt-0">
            <label class="label-input w-full block tracking-wide mb-2" to="input_street_address">Calle:</label>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="input_street_address" name="input_street_address"
             style="color:#3B3B3B;"
             v-model.trim="v$.adress_attributes.street.$model"
              :class="{'invalid-form':v$.adress_attributes.street.$error, 'valid-form':!v$.adress_attributes.street.$invalid}" 
              @blur="validateDiferenTo('input_between_street_address','input_and_street_address','input_street_address')"/>

            <div v-for="error of v$.adress_attributes.street.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          
          <div class="mt-10 sm:mt-0">
            <label class="label-input w-full block tracking-wide mb-2" to="input_exterior_number_address">Número Exterior:</label>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="input_exterior_number_address" name="input_exterior_number_address" 
            style="color:#3B3B3B;"
              v-model.trim="v$.adress_attributes.ext_number.$model"
             :class="{'invalid-form':v$.adress_attributes.ext_number.$error, 'valid-form':!v$.adress_attributes.ext_number.$invalid}" />
            
            <div v-for="error of v$.adress_attributes.ext_number.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>

          <div class="mt-10 sm:mt-0">
            <label class="label-input w-full block tracking-wide mb-2" to="input_interior_number_address">Número Interior:</label>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="input_interior_number_address" name="input_interior_number_address" 
            style="color:#3B3B3B;"
            v-model.trim="v$.adress_attributes.int_number.$model"
             :class="{'invalid-form':v$.adress_attributes.int_number.$error, 'valid-form': v$.adress_attributes.int_number.$model.length==1,}" />
            
            <div v-for="error of v$.adress_attributes.int_number.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-10 mt-10">
          <div class="mt-10 sm:mt-0">
            <label class="label-input w-full block tracking-wide mb-2" to="input_between_street_address">Entre Calle:</label>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="input_between_street_address" name="input_between_street_address" 
            style="color:#3B3B3B;"
              v-model.trim="v$.adress_attributes.between_street_one.$model"
             :class="{'invalid-form':v$.adress_attributes.between_street_one.$error, 'valid-form':!v$.adress_attributes.between_street_one.$invalid}"  @blur="validateDiferenTo('input_between_street_address','input_and_street_address','input_street_address')"/>
            
            <div v-for="error of v$.adress_attributes.between_street_one.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>

          <div class="mt-10 sm:mt-0">
            <label class="label-input w-full block tracking-wide mb-2" to="input_and_street_address">Y Calle:</label>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="input_and_street_address" name="input_and_street_address"
             style="color:#3B3B3B;"
               v-model.trim="v$.adress_attributes.between_street_two.$model"
             :class="{'invalid-form':v$.adress_attributes.between_street_two.$error, 'valid-form':!v$.adress_attributes.between_street_two.$invalid}" @blur="validateDiferenTo('input_between_street_address','input_and_street_address','input_street_address')" />
            
            <div v-for="error of v$.adress_attributes.between_street_two.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet" /> -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Address from "@/classes/Address"

import useVuelidate from '@vuelidate/core';
import { requiredIf, minLength, maxLength, helpers } from '@vuelidate/validators';

import('@/assets/css/stylesheet.css');
import('@/assets/css/referredperson.css');

export default {
  name: "AddressComponent",

  setup () {
    return { v$: useVuelidate() }
  },

  data() {
    return {
        addressClass:"",
        apiToken:"",
        multiApi:process.env.VUE_APP_MULTIAPI,
        suburbList:[],
        responseValidatios:"",
        adress_attributes:{
          city: "N/A",
          state: "N/A",
          status: "activo",
          street: "",
          suburb: "",
          country: "MÉXICO",
          latitude: "",
          longitude: "",
          ext_number: "",
          int_number: "",
          postal_code: "",
          municipality: "N/A",
          residence_start: "",
          residence_status: "",
          between_street_one: "",
          between_street_two: ""
        }
    };
  },

  props: {
    rulesValidations: {
      type: Object,
      default: () => {}
    },
    rulesLoaded: {
      type: Boolean,
      default: false
    },
  },
  
  validations(){
    return{
         adress_attributes:{
          postal_code:{
            required: helpers.withMessage('El campo es requerido' , requiredIf(function(){return this.rulesValidations.address.postalCode.required})),
            minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.address.postalCode.minLength} caracteres` , minLength(this.rulesValidations.address.postalCode.minLength)),
            maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.address.postalCode.maxLength} caracteres` , maxLength(this.rulesValidations.address.postalCode.maxLength))
          },
          city:{
            required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.address.city.required})),
          },
          state:{
            required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.address.state.required})),
          },
          street:{
            required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.address.street.required})),
            minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.address.street.minLength} caracteres` , minLength(this.rulesValidations.address.street.minLength)),
            maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.address.street.maxLength} caracteres` , maxLength(this.rulesValidations.address.street.maxLength))
          },
          suburb:{
            required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.address.suburb.required})),
          },
          municipality:{
            required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.address.municipality.required})),
          },
          ext_number:{
            required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.address.ext_number.required})),
            minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.address.ext_number.minLength} caracteres` , minLength(this.rulesValidations.address.ext_number.minLength)),
            maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.address.ext_number.maxLength} caracteres` , maxLength(this.rulesValidations.address.ext_number.maxLength))
          },
          int_number:{
            minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.address.int_number.minLength} caracteres` , minLength(this.rulesValidations.address.int_number.minLength)),
            maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.address.int_number.maxLength} caracteres` , maxLength(this.rulesValidations.address.int_number.maxLength))
          },
          between_street_one:{
            required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.address.between_street_one.required})),
            minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.address.between_street_one.minLength} caracteres` , minLength(this.rulesValidations.address.between_street_one.minLength)),
            maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.address.between_street_one.maxLength} caracteres` , maxLength(this.rulesValidations.address.between_street_one.maxLength))
          },
          between_street_two:{
            required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.address.between_street_two.required})),
            minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.address.between_street_two.minLength} caracteres` , minLength(this.rulesValidations.address.between_street_two.minLength)),
            maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.address.between_street_two.maxLength} caracteres` , maxLength(this.rulesValidations.address.between_street_two.maxLength))
          }
        },
        
    }
   
  },
  watch:{
    adress_attributes:{
      handler(data){
        this.$emit("adress_attributes",data)
      },
      deep: true
    },
    'adress_attributes.street':function ( ) {
      var regex = /^[0-9a-zA-ZÀ-ÿ-]+$/;
        if (regex.test(this.adress_attributes.street) != true) {
          this.adress_attributes.street = this.adress_attributes.street.replace(
            /[^0-9a-zA-ZÀ-ÿ-\s]+/,
            ""
          );
        }
    },
    'adress_attributes.ext_number':function () {
        var regex = /^[0-9]+$/;
          if (regex.test(this.adress_attributes.ext_number) != true) {
            this.adress_attributes.ext_number = this.adress_attributes.ext_number.replace(
            /[^0-9\s]+/,
            "");
          }
      },
      'adress_attributes.int_number':function() {
        var regex = /^[0-9-a-zA-Z-/]+$/;
        if (regex.test(this.adress_attributes.int_number) != true) {
          this.adress_attributes.int_number = this.adress_attributes.int_number.replace(
            /[^0-9-a-zA-Z-\/\s]+/,
            ""
          );
        }
      },
      'adress_attributes.between_street_one':function() {
         var regex = /^[0-9a-zA-ZÀ-ÿ-]+$/;
        if (regex.test(this.adress_attributes.between_street_one) != true) {
          this.adress_attributes.between_street_one = this.adress_attributes.between_street_one.replace(
            /[^0-9a-zA-ZÀ-ÿ-\s]+/,
            ""
          );
        }
      },
      'this.adress_attributes.between_street_two':function(){
        var regex = /^[0-9a-zA-ZÀ-ÿ-]+$/;
        if (regex.test(this.adress_attributes.between_street_two) != true) {
          this.adress_attributes.between_street_two = this.adress_attributes.between_street_two.replace(
            /[^0-9a-zA-ZÀ-ÿ-\s]+/,
            ""
          );
        }
      }
  },

  mounted(){
    this.addressClass = new Address({ street:"", suburb:"", ext_number:"", int_number:"", postal_code:"", between_street_one:"", between_street_two:"", city:"", state:"", country:"", municipality:"" });
    this.apiToken = sessionStorage.getItem("login");
  },
  methods: {
    validateDiferenTo(str1, str2, str3){
      let street1 = document.getElementById(str1).value;
      let street2 = document.getElementById(str2).value;
      let street3 = document.getElementById(str3).value;
    
      let response_validations = {};
      response_validations = this.structValidationDiferentTo(street1, street2, street3);
      if(response_validations != null){
        this.alertErrorValidationDifernTo(response_validations);
      }
       
    },
    structValidationDiferentTo(street1, street2, street3){
      let response_validations = null;
      if(street1 == street2){
        if(street1 != "" && street2 != ""){
          response_validations= {campo1: "ENTRE CALLE", campo2: "Y CALLE", campo3: ""};
          this.adress_attributes.between_street_two = "";
        }
      }

      if(street1==street3){
        if(street1!=""&&street3!=""){
          response_validations = { campo1: "CALLE Y ", campo2: "", campo3: "ENTRE CALLE" };
          this.adress_attributes.between_street_one = "";
        }
      }

      if(street2 == street3){
        if(street2 != "" && street3 != ""){
          response_validations= { campo1: "", campo2: "CALLE y el campo ", campo3: "Y CALLE" };
          this.adress_attributes.between_street_two = "";
        }
      }

      if(street1 == street2 && street1 == street3 ){
        if(street1 != "" && street2 != "" && street1 != "" && street3 != ""){
          response_validations = { campo1: "CALLE,", campo2: "ENTRE CALLE ", campo3: "Y CALLE" };
          this.adress_attributes.between_street_two = "";
          this.adress_attributes.between_street_one = "";
        }
      }
      
      return response_validations;
    },
    alertErrorValidationDifernTo(response_validations){
      Swal.fire({
        title: "Aviso",
        html: `El campo <strong>${response_validations.campo1}</strong> <strong>${response_validations.campo2}</strong> <strong>${response_validations.campo3}</strong> no puede ser el mismo`,
        icon: "warning",
        confirmButtonColor: '#FEB72B',
      });
    },
    async validateCP(postalCode) {
      if( postalCode != "" ){
        if( postalCode != null && postalCode.length == 5 ){
          this.addressClass.getColonies(postalCode).then(response=>{
            if (response.data.estado.nombre == "" && response.data.municipio == "") {
              Swal.fire({
                title: "Aviso",
                text: "Introduzca un código postal valido",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
            }else {
              this.suburbList = response.data.colonias
              this.adress_attributes.municipality = response.data.municipio.nombre != null ? response.data.municipio.nombre : ""
              this.adress_attributes.state = response.data.estado.nombre != null ? response.data.estado.nombre : ""
              if (response.data.ciudad.nombre != null) {
                this.adress_attributes.city = response.data.ciudad.nombre
              } else {
                this.adress_attributes.city="N/A"
              }
            }
        }).catch(error => {
          Swal.fire({
            title: "Aviso",
            text: "Introduzca un código postal valido",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });

          this.suburbList = []
          this.adress_attributes.municipality = "";
          this.adress_attributes.state  = "";
          this.adress_attributes.city = "";
        });
      } else {
        Swal.fire({
          title: "Aviso",
          text: "Introduzca un código postal valido",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
      }
    } else {
      this.suburbList = []
      this.adress_attributes.municipality = "N/A"
      this.adress_attributes.state  = "N/A"
      this.adress_attributes.city = "N/A"
    }
    },
    touch(){
      this.v$.$validate();
      return this.v$.$error;
    },
  },
};
</script>