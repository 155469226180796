<template>
  <div>
    <p class="label-input">{{label}}</p>
    <v-select 
      :class="classInput"
      inputId="input_search_person_referred"
      v-model="item"
      :filter="fuseSearch"
      :options="options"
      label="texto"
      :get-option-label="(option) => option.text"
      >

      <template v-slot:no-options>
        <span id="label_No_results_to_show">
          No hay resultado coincidentes.
        </span>
      </template>

    </v-select>
    <div v-if="v$.item.$error" class="text">
      <span class="errors" v-if="!v$.item.required" id="error_message_referred_person_required">La persona referida es requerida</span>
    </div>
  </div>
</template>

<script>

import { ref } from "vue";

import vSelect from "vue-select";

import("@/assets/css/referredperson.css");
import "vue-select/dist/vue-select.css";
import Fuse from 'fuse.js'

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: []
    },
    classInput: {
      type: String,
      default: 'shadow-md border-2 rounded-lg w-full px-4 py-4 mt-2'
    },
  },
  
  setup (props, { emit }) {
    const item = ref({value: '', text: ''});
    const optionsValue =  ref(props.options);

    return { v$: useVuelidate(), emit$: emit, item, optionsValue }
  },

  validations() {
    return {
      item: {
        required,
      },
    }
  },
  
  components: {
    vSelect
  },
  
  methods: {
  
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['text'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => {
          return item
          }).filter(b => b.text.toUpperCase().includes(search.toUpperCase()))
        : fuse.list
    },

    deselect (option) {
      console.log("deselect");
    }
  },

  watch: {
    item: function(value){
      this.emit$("event-search-select", value);
    }
  }
}
</script>

<style>
.vs__dropdown-toggle {
  border: none;
  padding: 0px !important;
}
.vs--open .vs__dropdown-toggle {
  border-bottom-color: #fff !important;
}
input[type=search] {
  margin:0px !important;
}
.vs__selected {
  margin: 0px !important;
}
.vs__dropdown-menu {
  border: none !important; 
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  @apply shadow-md border-2 !important;
}
</style>