<template>
  <div>
    <!-- Title -->
    <div class="flex mb-6">
      <div style="display: table">
        <div style="display: table-cell; vertical-align: middle">
          <img
            src="@/assets/CapturaAsistida/triangle.svg"
            style="width: 30px"
            alt="flecha"
          />
        </div>
      </div>
      <div style="display: table; margin-left: 10px">
        <div style="display: table-cell; vertical-align: middle">
          <label class="sub-title">Estado civil</label>
        </div>
      </div>
    </div>

    <!-- Form -->
    <div v-if="rulesLoaded" class="w-full md:ml-10">
      <div class="grid md:grid-cols-3 grid-cols-1 gap-10 mt-10">
        <!-- Civil status -->
        <div v-show="displayInputs.status">
          <label
            class="label-input w-full block tracking-wide mb-4"
            for="select_marital_status"
          >
            Estado civil:
          </label>

          <select
            v-model.trim="v$.civilStatus.status.$model"
            @change="onChangeCivilStatus()"
            name="select_marital_status"
            id="select_marital_status"
            :class="{
              'invalid-form': v$.civilStatus.status.$error,
              'valid-form': !v$.civilStatus.status.$invalid,
            }"
            @blur="v$.civilStatus.status.$touch"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
          >
            <option selected disabled value="">Seleccione un estado civil</option>
            <option value="Soltero(a)">Soltero(a)</option>
            <option value="Casado(a)">Casado(a)</option>
            <option value="Divorciado(a)">Divorciado(a)</option>
            <option value="Viudo(a)">Viudo(a)</option>
            <option value="Concubinato">Concubinato</option>
          </select>

          <div v-for="error of v$.civilStatus.status.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>
        </div>

        <!-- Regime of goods -->
        <div v-show="displayInputs.regimeOfGoods">
          <ChooseButton
            :buttons="buttonsRegimeOfGoods"
            :flex="true"
            v-model.trim="v$.civilStatus.regimeOfGoods"
            v-on:choose-button="getValueChooseButtonRegime($event)"
            label="Régimen de Bienes:"
          />

          <div v-for="error of v$.civilStatus.regimeOfGoods.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>
        </div>
      </div>

      <div class="grid md:grid-cols-3 grid-cols-1 gap-10 mt-10">
        <!-- RFC -->
        <div v-show="displayInputs.rfc">
          <label
            class="label-input w-full block tracking-wide mb-4"
            for="input_RFC_partner"
          >
            {{ v$.civilStatus.status.$model == 'Concubinato' ? 'R.F.C del concubino :': 'R.F.C del cónyuge:' }}
          </label>
          <input
            v-model.trim="v$.civilStatus.rfc.$model"
            name="input_RFC_partner"
            id="input_RFC_partner"
            v-maska="'AAAA######XXX'"
            @change="v$.civilStatus.rfc.$touch"
            :minlength="rulesValidations.civilStatus.rfc.maxLength"
            :maxlength="rulesValidations.civilStatus.rfc.maxLength"
            :class="{
              'invalid-form': v$.civilStatus.rfc.$error,
              'valid-form': !v$.civilStatus.rfc.$invalid,
            }"
            @blur="v$.civilStatus.rfc.$touch,validateStrucDatauserRFC()"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor uppercase"
          />

          <div v-for="error of v$.civilStatus.rfc.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>
        </div>

        <!-- Regime of goods -->
        <div v-show="displayInputs.dateOfBirth">
          <label
            class="label-input w-full block tracking-wide mb-4"
            for="select_birthdate_partner"
          >
            Fecha de nacimiento:
          </label>
          <p
            v-show="civilStatus.dateOfBirth"
            id="select_birthdate_partner"
            class="font-light mt-3 uppercase text-lg"
          >
            {{ civilStatus.dateOfBirth }}
          </p>
          <p
            v-show="!civilStatus.dateOfBirth"
            id="select_birthdate_partner"
            class="font-light mt-3 uppercase text-lg"
          >
            AAAA/MM/DD
          </p>
        </div>
      </div>

      <div class="grid md:grid-cols-3 grid-cols-1 gap-10 mt-10">
        <!-- Pathernal name -->
        <div v-show="displayInputs.paternalName">
          <label
            class="label-input w-full block tracking-wide mb-4"
            for="input_last_name_partner"
          >
            Apellido Paterno:
          </label>
          <input
            v-model.trim="v$.civilStatus.paternalName.$model"
            name="input_last_name_partner"
            id="input_last_name_partner"
            :class="{
              'invalid-form': v$.civilStatus.paternalName.$error,
              'valid-form': !v$.civilStatus.paternalName.$invalid,
            }"
            @blur="v$.civilStatus.paternalName.$touch,v$.civilStatus.rfc.$touch,validateStrucDatauserRFC()"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
          />

          <div v-for="error of v$.civilStatus.paternalName.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>
        </div>

        <!-- Mathernal name -->
        <div v-show="displayInputs.maternalName">
          <label
            class="label-input w-full block tracking-wide mb-4"
            for="input_mothers_last_name_partner"
          >
            Apellido Materno:
          </label>
          <input
            v-model.trim="v$.civilStatus.maternalName.$model"
            name="input_mothers_last_name_partner"
            id="input_mothers_last_name_partner"
            :class="{
              'invalid-form': v$.civilStatus.maternalName.$error,
              'valid-form': !v$.civilStatus.maternalName.$invalid,
            }"
            @blur="v$.civilStatus.maternalName.$touch,v$.civilStatus.rfc.$touch,validateStrucDatauserRFC()"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
          />

          <div v-for="error of v$.civilStatus.maternalName.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>
        </div>

        <!-- First Name -->
        <div v-show="displayInputs.firstName">
          <label
            class="label-input w-full block tracking-wide mb-4"
            for="input_first_name_partner"
          >
            Nombre:
          </label>
          <input
            v-model.trim="v$.civilStatus.firstName.$model"
            name="input_first_name_partner"
            id="input_first_name_partner"
            :class="{
              'invalid-form': v$.civilStatus.firstName.$error,
              'valid-form': !v$.civilStatus.firstName.$invalid,
            }"
            @blur="v$.civilStatus.firstName.$touch,v$.civilStatus.rfc.$touch,validateStrucDatauserRFC()"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
          />

          <div v-for="error of v$.civilStatus.firstName.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>
        </div>
      </div>

      <div class="grid md:grid-cols-3 grid-cols-1 gap-10 mt-10">
          <!-- Second Name -->
          <div v-show="displayInputs.secondName">
          <label class="label-input w-full block tracking-wide mb-4" for="input_second_name_partner">
            Segundo Nombre:
            </label>
            <input v-model.trim="v$.civilStatus.secondName.$model" name="input_second_name_partner" id="input_second_name_partner" 
            :class="{'invalid-form':v$.civilStatus.secondName.$error, 'valid-form':!v$.civilStatus.secondName.$invalid}" 
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            @blur="v$.civilStatus.secondName.$touch,v$.civilStatus.rfc.$touch,validateStrucDatauserRFC()">

            <div v-for="error of v$.civilStatus.secondName.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>

          <!-- Gender -->
          <div v-show="displayInputs.gender">
            <ChooseButton 
              :buttons="buttonsGenders"
              :containerClass="'md:flex'"
              v-model.trim="v$.civilStatus.gender"
              v-on:choose-button="getValueChooseButtonGenders($event)"
              label="Género:" />

            <div v-for="error of v$.civilStatus.gender.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import User from "@/classes/User";
import ChooseButton from "@/components/Forms/Buttons/ChooseButton.vue";
import Date from "@/components/Forms/Selects/Date";
import texts from "@/helpers/texts";
import moment from "moment";
import("@/assets/css/referredperson.css");
import Swal from "sweetalert2";

import useVuelidate from "@vuelidate/core";
import {
  minLength,
  maxLength,
  requiredIf,
  helpers,
} from "@vuelidate/validators";
import { ref, reactive } from "vue";

export default {
  name: "CivilStatus",

  data() {
    return {
      isRfcValid: reactive(true),
      isRequiredRfc: reactive(true),
      userObjectClass: reactive(null),
      civilStatusList: reactive([]),
      displayInputs: reactive({
        status: true,
        regimeOfGoods: false,
        rfc: false,
        dateOfBirth: false,
        paternalName: false,
        maternalName: false,
        firstName: false,
        secondName: false,
        gender: false,
      }),
      civilStatus: reactive({
        status: "",
        regimeOfGoods: "",
        rfc: "",
        dateOfBirth: "",
        paternalName: "",
        maternalName: "",
        firstName: "",
        secondName: "",
        gender: "",
      }),
      buttonsRegimeOfGoods: reactive([
        { id: "button_man", value: "mancumunados", text: "Mancumunados" },
        { id: "button_sep", value: "separados", text: "Separados" },
      ]),
      buttonsGenders: reactive([
        { id: "button_gender_partner_male", value: "masculino", text: "Hombre" },
        { id: "button_gender_partner_female", value: "femenino", text: "Mujer" },
      ]),
    };
  },

  props: {
    rulesValidations: {
      type: Object,
      default: () => {},
    },
    rulesLoaded: {
      type: Boolean,
      default: false,
    },
    memberRfc: {
      type: String,
      default: ''
    }
  },

  setup() {
    const regexRfc = ref("^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                      "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                      "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                      "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$");
    return { v$: useVuelidate(), regexRfc };
  },

  validations() {
    return {
      civilStatus: {
        status: {
          required: helpers.withMessage(
            "El campo es requerido",
            requiredIf(function () {
              return this.rulesValidations.civilStatus.status.required;
            })
          ),
        },
        regimeOfGoods: {
          required: helpers.withMessage(
            "El campo es requerido",
            requiredIf(function () {
              if (!this.rulesValidations.civilStatus.regimeOfGoods.required) {
                return false;
              }

              return this.civilStatus.status == "Casado(a)" ? true : false;
            })
          ),
        },
        rfc: {
          required: helpers.withMessage(
            "El campo es requerido",
            requiredIf(function () {
              if (!this.rulesValidations.civilStatus.rfc.required) {
                return false;
              }

              return this.civilStatus.status == "Casado(a)" ||
                this.civilStatus.status == "Concubinato"
                ? true
                : false;
            })
          ),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.civilStatus.rfc.minLength} caracteres` ,  minLength(this.rulesValidations.civilStatus.rfc.minLength)),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.civilStatus.rfc.maxLength} caracteres` ,  maxLength(this.rulesValidations.civilStatus.rfc.maxLength)),
          structureIsValid: helpers.withMessage(
            "El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0.",
            function (value) {
              if ( !this.rulesValidations.civilStatus.rfc.required || value == "" ) 
              {
                return true;
              }

              if ( this.civilStatus.status == "Casado(a)" ) {

                if ( this.rulesValidations.civilStatus.rfc.minLength > value.length ) {
                  return false;
                }

                const request = this.validateRfcStructure(value);
                return request.status ? true : false;
              }

              return true;
            }
          ),
          formatValidator:
            async function (value) {
              if ( !this.rulesValidations.civilStatus.rfc.required || value == "" || !this.isRequiredRfc)
              {
                return false;
              }

              if (
                this.civilStatus.firstName.length < this.rulesValidations.civilStatus.firstName.minLength &&
                (this.civilStatus.paternalName.length < this.rulesValidations.civilStatus.paternalName.minLength ||
                this.civilStatus.maternalName.length < this.rulesValidations.civilStatus.maternalName.minLength)
              ) {
                return false;
              }

              if ( this.civilStatus.status == "Casado(a)" || this.civilStatus.status == "Concubinato" )
              {
                if ( value.toString().length < this.rulesValidations.civilStatus.rfc.minLength && !this.v$.civilStatus.rfc.structureIsValid )
                {
                  return false;
                }
                
                if ((new RegExp(this.regexRfc).test(value.toString()))) {
                  const isValid = await new Promise((resolve, reject) => {
                    this.validateCompleteRfc().then(res=>{
                      this.isRfcValid = !res;
                      resolve(!res);
                      return !res;
                    }).catch(err=>{
                      this.isRfcValid = false;
                      reject(!err);
                      return false;
                    });
                  });

                  // console.log("Isvalidate ::",isValid)

                  if (!isValid) {
                    // Muestra mensaje de excepcion de rfc y nombre con apellido invalido
                    this.exceptionMessageRFC();
                  }

                  return Boolean(isValid);
                }
                return false;
              }
              return false;
            }
          ,
          isValid: helpers.withMessage("Los datos de nombre no coinciden con el RFC", function(){
            if(!this.isRequiredRfc){
              return true;
            }

            return this.isRfcValid;
          }),
          isDinstinct: helpers.withMessage("El RFC del cónyuge no puede ser el mismo que el del socio",function(value){
            if(value == "" && this.memberRfc == "")
              return true;
            return value == this.memberRfc? false: true;
          }),
          underage: helpers.withMessage("El cónyuge no puede ser menor de edad",function(value){
            if(value == "")
              return true;
            var time = moment(this.civilStatus.dateOfBirth).fromNow();
            var t = time.split(" ");
            return t[0]<18? false: true;
          })
        },
        paternalName: {
          required: helpers.withMessage(
            "El campo es requerido",
            requiredIf(function () {
              
              if (!this.rulesValidations.civilStatus.paternalName.required) {
                return false;
              }

              return (this.civilStatus.status == "Casado(a)" ||
                this.civilStatus.status == "Concubinato") &&
                this.civilStatus.maternalName == ""
                ? true
                : false;
            })
          ),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.civilStatus.paternalName.minLength} caracteres`, minLength(
            this.rulesValidations.civilStatus.paternalName.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.civilStatus.paternalName.maxLength} caracteres`,maxLength(
            this.rulesValidations.civilStatus.paternalName.maxLength
          )),
        },
        maternalName: {
          required: helpers.withMessage(
            "El campo es requerido",
            requiredIf(function () {
              if (!this.rulesValidations.civilStatus.maternalName.required) {
                return false;
              }

              return (this.civilStatus.status == "Casado(a)" ||
                this.civilStatus.status == "Concubinato") &&
                this.civilStatus.paternalName == ""
                ? true
                : false;
            })
          ),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.civilStatus.maternalName.minLength} caracteres`, minLength(
            this.rulesValidations.civilStatus.maternalName.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.civilStatus.maternalName.maxLength} caracteres`, maxLength(
            this.rulesValidations.civilStatus.maternalName.maxLength
          )),
        },
        firstName: {
          required: helpers.withMessage(
            "El campo es requerido",
            requiredIf(function () {
              if (!this.rulesValidations.civilStatus.firstName.required) {
                return false;
              }

              return this.civilStatus.status == "Casado(a)" ||
                this.civilStatus.status == "Concubinato"
                ? true
                : false;
            })
          ),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.civilStatus.firstName.minLength} caracteres`, minLength(
            this.rulesValidations.civilStatus.firstName.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.civilStatus.firstName.maxLength} caracteres`, maxLength(
            this.rulesValidations.civilStatus.firstName.maxLength
          )),
        },
        secondName: {
          required: helpers.withMessage(
            "El campo es requerido",
            requiredIf(function () {
              if (!this.rulesValidations.civilStatus.secondName.required) {
                return false;
              }

              return this.civilStatus.status == "Casado(a)" ||
                this.civilStatus.status == "Concubinato"
                ? true
                : false;
            })
          ),
          minLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.civilStatus.secondName.minLength} caracteres`, minLength(
            this.rulesValidations.civilStatus.secondName.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.civilStatus.secondName.maxLength} caracteres`, maxLength(
            this.rulesValidations.civilStatus.secondName.maxLength
          )),
        },
        gender: {
          required: helpers.withMessage(
            "El campo es requerido",
            requiredIf(function () {
              if (!this.rulesValidations.civilStatus.gender.required) {
                return false;
              }

              return this.civilStatus.status == "Casado(a)" ||
                this.civilStatus.status == "Concubinato"
                ? true
                : false;
            })
          ),
        },
      },
    };
  },

  methods: {
    exceptionMessageRFC() {
      Swal.fire({
        title: "Los datos de nombre no coinciden con el RFC.",
        html: `¿Es correcto y desea continuar con el registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isRequiredRfc = false;
        } else {
          this.isRequiredRfc = true;
        }
      });
    },
    validateRfcStructure(rfc) {
      rfc = rfc.toUpperCase();
      let rfc_pattern =
        "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
      const coincidences = rfc.match(rfc_pattern);

      const data = [];
      if (coincidences != null) {
        coincidences.forEach((element) => {
          if (typeof element != "undefined") {
            data.push(element);
          }
        });
      }
      return coincidences == null
        ? { status: false, coincidences: data }
        : { status: true, coincidences: data };
    },
    onChangeCivilStatus() {
      this.v$.$reset();
      if (this.civilStatus.status == "Casado(a)" || this.civilStatus.status == "Concubinato")
      {
        if(this.civilStatus.status == "Casado(a)") 
        {
          this.displayInputs.regimeOfGoods = true;
        }
        if(this.civilStatus.status == "Concubinato") 
        {
          this.displayInputs.regimeOfGoods = false;
        }
        this.displayInputs.rfc = true;
        this.displayInputs.dateOfBirth = true;
        this.displayInputs.paternalName = true;
        this.displayInputs.maternalName = true;
        this.displayInputs.firstName = true;
        this.displayInputs.secondName = true;
        this.displayInputs.gender = true;
      }
      else 
      {
        this.civilStatus.regimeOfGoods = "";
        this.civilStatus.rfc = "";
        this.civilStatus.dateOfBirth = "";
        this.civilStatus.paternalName = "";
        this.civilStatus.maternalName = "";
        this.civilStatus.firstName = "";
        this.civilStatus.secondName = "";
        this.civilStatus.gender = "";

        this.displayInputs.regimeOfGoods = false;
        this.displayInputs.rfc = false;
        this.displayInputs.dateOfBirth = false;
        this.displayInputs.paternalName = false;
        this.displayInputs.maternalName = false;
        this.displayInputs.firstName = false;
        this.displayInputs.secondName = false;
        this.displayInputs.gender = false;
        this.isRequiredRfc = false;
      }
    },
    touch() {
      this.onChangeCivilStatus();
      this.v$.$validate();
      return this.v$.$error;
    },
    getValueChooseButtonRegime(value) {
      this.civilStatus.regimeOfGoods = value;
    },
    getValueChooseButtonGenders(value) {
      this.civilStatus.gender = value;
    },
    getResponseDate(value) {
      this.civilStatus.dateOfBirth = value;
    },
    validateCompleteRfc() {
      try {
        // || this.civilStatus.maternalName != ""
        if (
          this.civilStatus.rfc != "" &&
          this.civilStatus.dateOfBirth != "" &&
          this.civilStatus.firstName != "" &&  this.civilStatus.paternalName != ""
            
        ) {
          return this.userObjectClass
            .rfcFormatIsValid({
              rfc: this.civilStatus.rfc,
              dateOfBirth: this.civilStatus.dateOfBirth,
              firstName: this.civilStatus.firstName,
              secondName: this.civilStatus.secondName,
              paternalName: this.civilStatus.paternalName,
              maternalName: this.civilStatus.maternalName,
            })
            .then((res) => {
              return res.data.response.has_errors;
            })
            .catch((err) => {
              return err.data.response.has_errors;
            });
        }
      } catch (error) {
        return false;
      }
    },
    validateHasOnlyLetters(value, modelInput) {
      if (value != "") {
        const hasOnlyLetters =
          this.userObjectClass.validateHasOnlyLetters(value);
        if (!hasOnlyLetters) {
          this.civilStatus[modelInput] = texts.removeTextByRegex(
            value,
            /[^a-zA-ZÀ-ÿ@\s-]+/
          );
        }
      }
    },
    getBirthdayByRfc(value) {
      //TODO: cambiar este metodo por servicio get_date_by_rfc
      var m = value.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
      var year = parseInt(m[1],10)+1900;
      if( year < 1925 ) year += 100;
      var month = parseInt(m[2], 10);
      var day = parseInt(m[3], 10);
      // console.log("Fecha:", `${year}-${month}-${day}`);
      return `${year}-${month}-${day}`;
    },
    validateNames(firstName,secondName){
      if(firstName!="" || secondName!="") {
         return true
      }else{
        return false
      }
    },
    async validateStrucDatauserRFC(){
      let seconName=this.validateNames(this.civilStatus.paternalName, this.civilStatus.maternalName)
      let names=this.validateNames(this.civilStatus.firstName, this.civilStatus.secondName)
      if(this.civilStatus.rfc!=""  && this.civilStatus.dateBirth!="" && names && seconName ){
        if (
              !this.rulesValidations.civilStatus.rfc.required ||
              this.civilStatus.rfc == "" ||
              !this.isRequiredRfc
            ) {
              return true;
            }

            if (
              this.civilStatus.rfc.toString().length < this.rulesValidations.civilStatus.rfc.minLength &&
              !this.v$.civilStatus.rfc.structureIsValid
            ) {
              return false;
            }
            
            const request = this.validateRfcStructure(this.civilStatus.rfc.toUpperCase());
            if (request.status) {
              
              const isValid = await new Promise((resolve, reject) => {
                this.validateCompleteRfc().then(res=>{
                  this.isRfcValid = !res;
                  resolve(!res);
                  return !res;
                }).catch(err=>{
                  this.isRfcValid = false;
                  reject(!err);
                  return false;
                });
              });

              // console.log("El campo es valido",isValid)

              if (!isValid) {
                // Muestra mensaje de excepcion de rfc y nombre con apellido invalido
                this.exceptionMessageRFC();
              }
              
              return isValid;
            }
            return false;
      }
    }
  },

  beforeMount() {
    this.userObjectClass = new User();
  },

  mounted() {
    //Obtener token de autorizacion
    var auth = sessionStorage.getItem("login");
    this.apiToken = auth;
  },

  watch: {
    civilStatus: {
      deep: true,
      handler(data) {
        this.$emit("civil-status-data", data);
      },
    },
    "civilStatus.paternalName": async function (value) {
      if(value.length >= this.rulesValidations.civilStatus.paternalName.minLength){
        this.isRequiredRfc = true;
      }
      
      this.validateHasOnlyLetters(value, "paternalName");
    },
    "civilStatus.maternalName": async function (value) {
      if(value.length >= this.rulesValidations.civilStatus.maternalName.minLength){
        this.isRequiredRfc = true;
      }

      this.validateHasOnlyLetters(value, "maternalName");
    },
    "civilStatus.firstName": async function (value) {
      if(value.length >= this.rulesValidations.civilStatus.firstName.minLength){
        this.isRequiredRfc = true;
      }

      this.validateHasOnlyLetters(value, "firstName");
    },
    "civilStatus.secondName": function (value) {
      this.validateHasOnlyLetters(value, "secondName");
    },
    "civilStatus.rfc": function (value) {
      this.isRequiredRfc = true;
      if (value.length < this.rulesValidations.civilStatus.rfc.minLength) {
        this.civilStatus.dateOfBirth = "";
        return;
      }

      if (value.length >= this.rulesValidations.civilStatus.rfc.minLength) {
        const dateOfBirth = this.getBirthdayByRfc(value);
        this.civilStatus.dateOfBirth = moment(dateOfBirth).format("YYYY/MM/DD");
      }
    },
  },

  components: {
    ChooseButton,
    Date,
  },
};
</script>

<style scoped>
select {
  font-family: "HelveticaNeueLight";
  color: #3b3b3b;
  font-size: 16px;
}
.text-dark {
  color: #650a35;
}
.bg-dark {
  background: #650a35;
}
</style>

