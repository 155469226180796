<template>
  <div>
    <!-- Title -->
    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="">
        </div>
      </div>
      <div style="display:table;margin-left:10px;" v-if="rulesLoaded">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Escolaridad</label>
        </div>
      </div>
    </div>

    <!-- Form -->
    <div class="mt-10 grid grid-cols-1 sm:grid-cols-3 md:ml-10 w-full gap-10" v-if="rulesLoaded">
      <div>
        <label
          class="label-input w-full block tracking-wide mb-2"
          for="select_scholarship">
          Escolaridad:
        </label>
        <select
          id="select_scholarship"
          v-model.trim="v$.educationLevel.$model"
          :class="{'invalid-form':v$.educationLevel.$error, 'valid-form':!v$.educationLevel.$invalid}" 
          class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor">
          <option value="seleccionar" selected hidden>Seleccione un grado escolar</option>
          <option :value="item.value" v-for="(item, i) in educationLevels" :key="i">{{item.text}}</option>
        </select>

        <div v-for="error of v$.educationLevel.$errors" :key="error.$message">
          <div class="errors">{{ error.$message }}</div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import("@/assets/css/referredperson.css");
import useVuelidate from '@vuelidate/core';
import { requiredIf, helpers } from '@vuelidate/validators';

export default {

  name: 'ScholarShipInformation',

  props: {
    rulesValidations: {
      type: Object,
      default: () => {}
    },
    rulesLoaded: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      educationLevels: [
        {value: 'primaria', text: 'Primaria'},
        {value: 'secundaria', text: 'Secundaria'},
        {value: 'preparatoria', text: 'Preparatoria'},
        {value: 'Técnica comercial', text: 'Técnica comercial'},
        {value: 'licenciatura', text: 'Licenciatura'},
        {value: 'posgrado', text: 'Posgrado'},
      ],
      educationLevel: 'seleccionar'
    }
  },

  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      educationLevel: {
        required: helpers.withMessage(`El campo es requerido` ,requiredIf(function() {
          return this.rulesValidations.scholarship.educationLevel.required;
        })),
        isUnique: helpers.withMessage(`El campo es requerido` , function(value) {
          if (this.rulesValidations.scholarship.educationLevel.required && value == 'seleccionar')
            return false;
          return true;
        })
      }
    }
  },
  
  watch: {
    educationLevel: {
      handler(data) {
        this.$emit('scholarship-information-data', data);
      },
      deep: true
    }
  },

  methods: {
    touch(){
      this.v$.$validate();
      return this.v$.$error;
    }
  }
}
</script>