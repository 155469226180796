<template>
  <div>
    <Loading :isLoading="this.isLoading"/>
    <!-- Title -->
    <div class="flex mb-6"  >
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="flecha">
        </div>
      </div>
      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Lugar de nacimiento</label>
        </div>
      </div>
    </div>

    <!-- Form -->
    <div class="flex flex-wrap mb-6 md:ml-10" v-if="rulesLoaded">
      
      <!-- Nacionality -->
      <div  class="flex flex-wrap w-full inline md:w-1/3 mb-6 md:mb-6">
        <ChooseButton 
          :buttons="buttonsNacionality"
          v-on:choose-button="getValueChooseButtonNacionality($event)"
          v-model="buttonsCountry"
          label="País:" />
      </div>
      
      <!-- Select countries -->
      <div v-show="showInputCountry && countries.length > 0" class="w-full md:w-1/3 px-3 mb-6 md:mb-6">
        <label class="label-input w-full block tracking-wide mb-2" for="button_country_birthplace">
        País:
        </label>
        <select
          id="button_country_birthplace"
          v-model.trim="v$.birthPlace.country.$model"
          :class="{'invalid-form':v$.birthPlace.country.$error, 'valid-form':!v$.birthPlace.country.$invalid}"
          class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor.5 mt-2"
        >
          <option value="" selected disabled>Seleccione un país</option>
          <option
            v-for="country in countries"
            :key="country.value"
            :value="country.value"
            :disabled="(country.value=='México')? true: false"
            >
            {{ country.text }}
          </option>
        </select>

        <div v-for="error of v$.birthPlace.country.$errors" :key="error.$message">
          <div class="errors">{{ error.$message }}</div>
        </div>
      </div>

      <!-- Select states -->
      <div v-show="!showInputCountry && states.length > 0" class="w-full md:w-1/3 px-3 mb-6 md:mb-6">
        <label class="label-input w-full block tracking-wide mb-2" for="select_state_birthplace">
        Estado:
        </label>
        <select
          id="select_state_birthplace"
          v-model="v$.birthPlace.state.$model"
          :class="{'invalid-form':v$.birthPlace.state.$error, 'valid-form':!v$.birthPlace.state.$invalid}"
          class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor.5 mt-2"
        >
          <option value="" selected disabled>Seleccione un estado</option>
          <option
            v-for="state in states"
            :key="state.value"
            :value="state.value"
            >{{ state.text }}</option
          >
        </select>
        
        <div v-for="error of v$.birthPlace.state.$errors" :key="error.$message">
          <div class="errors">{{ error.$message }}</div>
        </div>
      </div>

      <!-- Select municipalities -->
      <div v-show="!showInputCountry && municipalities.length > 0" class="w-full md:w-1/3 px-3 mb-6 md:mb-6">
        <label class="label-input w-full block tracking-wide mb-2" for="select_municipality_birthplace">
        Municipio:
        </label>
        <select
          id="select_municipality_birthplace"
          v-model="v$.birthPlace.municipality.$model"
          :class="{'invalid-form':v$.birthPlace.municipality.$error, 'valid-form':!v$.birthPlace.municipality.$invalid}"
          class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor.5 mt-2"
        >
          <option value="" selected disabled>Seleccione un municipio</option>
          <option
            v-for="municipality in municipalities"
            :key="municipality.value"
            :value="municipality.value"
            >{{ municipality.text }}</option
          >
        </select>

        <div v-for="error of v$.birthPlace.municipality.$errors" :key="error.$message">
          <div class="errors">{{ error.$message }}</div>
        </div>
      </div>

      <!-- Select cities -->
      <div v-show="!showInputCountry && cities.length > 0" class="w-full md:w-1/3 px-3 mb-6 md:mb-6">
        <label class="label-input w-full block tracking-wide mb-2" for="select_city_birthplace">
        Ciudad:
        </label>
        <select
          id="select_city_birthplace"
          v-model="v$.birthPlace.city.$model"
          :class="{'invalid-form':v$.birthPlace.city.$error, 'valid-form':!v$.birthPlace.city.$invalid}"
          class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor.5 mt-2"
        >
          <option value="" selected disabled>Seleccione una ciudad</option>
          <option
            v-for="city in cities"
            :key="city.value"
            :value="city.value"
            >{{ city.text }}</option
          >
        </select>

        <div v-for="error of v$.birthPlace.city.$errors" :key="error.$message">
          <div class="errors">{{ error.$message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import { requiredIf, helpers } from '@vuelidate/validators';

import Address from "@/classes/Address";
import ChooseButton from '@/components/Forms/Buttons/ChooseButton.vue';
import Loading from '@/components/Loading/VueLoading.vue';

import('@/assets/css/referredperson.css');

export default {
  name: "BirthPlace",

  props: {
    rulesValidations: {
      type: Object,
      default: () => {}
    },
    rulesLoaded: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      buttonsCountry: "",
      countries: [],
      states: [],
      municipalities: [],
      cities: [],
      birthPlace: {
        country: "",
        state: "",
        municipality: "",
        city: ""
      },
      UserObjectsClass: null,
      apiToken: "",
      isLoading: true,
      showInputCountry: false,
      buttonsNacionality: [
        { id: 'button_mexico_birthplace', value: false, text: 'México' },
        { id: 'button_other_birthplace', value: true, text: 'Otro' }
      ],
    };
  },

  validations() {
    return {
      birthPlace: {
        country: {
          required: helpers.withMessage('El campo es requerido' , requiredIf(function(){
            if(!this.rulesValidations.birthPlace.country.required){
              return false;
            }

            if(!this.buttonsCountry){
              return false;
            }
            return true;
          }))
        },
        state: {
          required: helpers.withMessage('El campo es requerido' , requiredIf(function(){
            if(!this.buttonsCountry){
              return (this.rulesValidations.birthPlace.state.required)? true: false;
          }
          return false;
         }))
        },
        municipality: {
          required: helpers.withMessage('El campo es requerido' , requiredIf(function(){
            if(!this.buttonsCountry){
              return (this.rulesValidations.birthPlace.municipality.required)? true: false;
          }
          return false;
         }))
        },
        city: {
          required: helpers.withMessage('El campo es requerido' , requiredIf(function(){
            if(!this.buttonsCountry){
              return (this.rulesValidations.birthPlace.city.required && this.cities.length > 0)? true: false;
            }
            return false;
          }))
        }
      }
    }
  },

  methods: {
    touch(){
      this.v$.$validate();
      return this.v$.$error;
    },
    getCountries() {
      //Obtener países
      this.UserObjectsClass.getCountries(this.apiToken)
        .then((response) => {

          if(!response.data.response.countries)
            throw "countries not found";

          const resp = response.data.response.countries;
          this.countries = resp.map(function(x) {
            return { value: x.name, text: x.name };
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    getStates() {
      //Obtener estados
      this.UserObjectsClass.getStates(this.apiToken)
        .then((response) => {

          if(!response.data.Estados)
            throw "states not found";

          const resp = response.data.Estados;
          this.states = resp.map(function(x) {
            return { value: x.nombre, text: x.nombre };
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    getMunicipalities(state) {
      //Obtener estados
      this.UserObjectsClass.getMunicipalities(this.apiToken, state)
        .then((response) => {

          if(!response.data.municipios)
            throw "municipalities not found";

          const resp = response.data.municipios;

          this.municipalities = resp.map(function(x) {
            return { value: x.nombre, text: x.nombre };
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    getCities(state, municipality) {
      //Obtener estados
      this.UserObjectsClass.getCities(this.apiToken, state, municipality)
        .then((response) => {

          if(!response.data.ciudades)
            throw "cities not found";

          const resp = response.data.ciudades;
          this.cities = resp.map(function(x) {
            return { value: x.nombre, text: x.nombre };
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    reloadInputs(exceptionInput="") {
      switch (exceptionInput) {
        case "country":
          this.states = [];
          this.municipalities = [];
          this.cities = [];

          this.birthPlace.state = "";
          this.birthPlace.municipality = "";
          this.birthPlace.city = "";

          break;
        case "state":
          this.municipalities = [];
          this.cities = [];

          this.birthPlace.municipality = "";
          this.birthPlace.city = "";
          break;
        case "municipality":
          this.cities = [];
          this.city = "";
          break;
        default:
          this.countries = [];
          this.states = [];
          this.municipalities = [];
          this.cities = [];

          this.birthPlace.country = "";
          this.birthPlace.state = "";
          this.birthPlace.municipality = "";
          this.birthPlace.city = "";
          break;
      }
    },
    getValueChooseButtonNacionality(status){
        this.isLoading = true;
        this.showInputCountry = status;
        this.buttonsCountry = status;
        
        this.reloadInputs();// Limpiar otros inputs
        this.v$.$reset();// Reiniciar validaciones
        if(!status){
          //Cargar estados
          this.getStates();
        }else{
          //Cargar países
          this.getCountries();
        }
    },
  },

  beforeMount() {
    //Obtener token de autorizacion
    this.UserObjectsClass = new Address({ street:"", suburb:"", ext_number:"", int_number:"", postal_code:"", between_street_one:"", between_street_two:"", city:"", state:"", country:"", municipality:"" });
    var auth = sessionStorage.getItem("login");
    this.apiToken = auth;
  },

  mounted() {
    this.getValueChooseButtonNacionality(false);
  },

  watch: {
    'birthPlace.state': function(value) {
      this.reloadInputs("state");
      this.isLoading = true;
      if (value != "") {
        this.getMunicipalities(value);
      }
    },
    'birthPlace.municipality': function(value) {
      this.getCities(this.birthPlace.state, value);
      this.isLoading = true;
      if (value != "") {
        this.reloadInputs("municipality");
        return;
      }
    },
    buttonsNacionality:{
      
    },
    birthPlace: {
      deep: true,
      handler(data) {
        if(this.buttonsCountry == false){//Si selecciono la opcion de Nacionalidad México
          data.country = "México";
        }else if(data.country == "México" && this.buttonsCountry == true){//Si selecciono la opcion de Otra nacionalidad
          data.country = "";
        }
        
        this.$emit('birth-place-data', data);
      }
    }
  },

  components: {
    ChooseButton,
    Loading
  },
};
</script>

<style scoped>
select {
  font-family: "HelveticaNeueLight";
  color: #3B3B3B;
  font-size: 16px;
}
.text-dark {
  color: #650A35;  
}
.bg-dark {
  background: #650A35;
}
</style>
