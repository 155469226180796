<template>
  <div>
    <!-- Title -->
    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="">
        </div>
      </div>
      <div class="md:ml-4" style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Registro de Identificación Oficial</label>
        </div>
      </div>
    </div>

    <!-- Forms -->
    <div class="md:ml-10">

      <div class="mt-10">

        <UploadFile 
          ref="uploadFileComponent"
          :documentsAllowed="documentsAllowed"
          :maxSizeBytesToUpload="maxSizeBytesForDocuments"
          :maxSizeMegaBytesToUpload="maxSizeMegaBytesForDocuments"
          :maxFiles="maxFiles"
          :minFiles="minFiles"
          v-on:upload-file="getUploadFileResponse($event)"
          v-on:deleted-file="getDeletedFileResponse($event)" />

      </div>

      <div>
          <tags
            :disableInput="true"
            :confirmDelete="true"
            :tags="documentTags" 
            v-on:deleted-file="getDeletedTag($event)"
            style="margin-top:30px;">
          </tags>
      </div>

      <div class="grid grid-cols-2 gap-2">
        <!-- Tipo de documento -->
        <div class="mt-10">
          <label class="label-input w-full block tracking-wide mb-3" for="select_document_type">Tipo de documento:</label>
          <select
              v-model.trim="v$.document_type.$model"
              name="select_document_type"
              id="select_document_type"
              :class="{
                'invalid-form': v$.document_type.$error,
                'valid-form': !v$.document_type.$invalid,
              }"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            >
              <option v-for="(document_type, index) in documents_type_list" :value="document_type" :key="index" :id="'document_type_'+document_type"> {{document_type}} </option>
          </select>

          <div v-for="error of v$.document_type.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>
        </div>

        <!-- Número de identificación INE/IFE -->
        <div class="mt-10" v-if="v$.document_type.$model == 'INE/IFE'">
          <label class="label-input w-full block tracking-wide mb-3" for="input_identification_number">
            Número de identificación:
          </label>
          <input
            id="input_identification_number"
            name="input_identification_number"
            type="text"
            v-maska="'#############'"
            v-model.trim="v$.identification_number.$model"
            :class="{
              'invalid-form': v$.identification_number.$error,
              'valid-form': !v$.identification_number.$invalid,
            }"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
          />

          <div v-for="error of v$.identification_number.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>

        </div>

        <!-- Número de pasaporte -->
        <div class="mt-10" v-if="v$.document_type.$model == 'Pasaporte'">
          <label class="label-input w-full block tracking-wide mb-3" for="input_passport_number">
            Número de pasaporte:
          </label>
          <input
            id="input_passport_number"
            name="input_passport_number"
            type="text"
            v-maska="'XXXXXXXXXX'"
            v-model.trim="v$.passport_number.$model"
            :class="{
              'invalid-form': v$.passport_number.$error,
              'valid-form': !v$.passport_number.$invalid,
            }"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
          />

          <div v-for="error of v$.passport_number.$errors" :key="error.$message">
            <div class="errors">{{ error.$message }}</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from '@/components/Forms/UploadFile/UploadFile';
import TagsInput from '@voerro/vue-tagsinput';
import Tags from '@/components/Forms/Inputs/Tags/Tag';

import useVuelidate from "@vuelidate/core";
import { helpers, minLength,maxLength ,requiredIf} from '@vuelidate/validators';

import validations from '@/helpers/validations';

export default {
  name: 'IdentificationRegisterComponent',

  data() {
    return {
      documents_type_list: [
        "INE/IFE",
        "Pasaporte"
      ],
      identification_number: "",
      passport_number: "",
      document_type: "",
      documentTags: [],
      documentsAllowed: [],
      maxSizeBytesForDocuments: 0,
      maxSizeMegaBytesForDocuments: 0,
      maxFiles: 0,
      minFiles: 0,
      files: []
    }
  },

  setup(){
    return { v$: useVuelidate() }
  },

  beforeMount() {
    this.getStructureValidations();
  },

  validations() {
    return {
      identification_number:{
        required: helpers.withMessage("El campo es requerido",  requiredIf(function(value, e){

          if(e.document_type == "INE/IFE"){
            e.passport_number = "";
            return true;
          }

          return false;
        })),
        minLength: helpers.withMessage("El campo debe contener mínimo 13 caracteres.", minLength(13)),
        maxLength: helpers.withMessage("El campo debe contener máximo 13 caracteres.", maxLength(13))
      },
      passport_number: {
        required: helpers.withMessage("El campo es requerido",  requiredIf(function(value, e){
          
          if(e.document_type == "Pasaporte"){
            e.identification_number = "";
            return true;
          }

          return false;
        })),
        minLength: helpers.withMessage("El campo debe contener mínimo 10 caracteres.", minLength(10)),
        maxLength: helpers.withMessage("El campo debe contener máximo 10 caracteres.", maxLength(10))
      },
      document_type: {
        required: helpers.withMessage("El campo es requerido",  requiredIf(function(value, e){
          return true;
        }))
      }
    }
  },

  methods: {
    touch(){
      this.v$.$validate();
      return this.v$.$error;
    },
    getUploadFileResponse(event) {
      console.log("event: ",event);
      this.addTag(event.newFile.name);

      this.files = event;
      this.$emit('identification-register-data', {
        files: event,
        identification_number: this.v$.identification_number.$model,
        passport_number: this.v$.passport_number.$model,
        document_type: this.v$.document_type.$model
      });
    },
    getDeletedFileResponse(event) {
      console.log("event: ",event);

      this.files = event;
      this.$emit('identification-register-data', {
        files: event,
        identification_number: this.v$.identification_number.$model,
        passport_number: this.v$.passport_number.$model,
        document_type: this.v$.document_type.$model
      });
    },
    addTag(tag) {
      this.documentTags.push({value: tag, text: tag});
    },
    getDeletedTag(event) {
      console.log("deletedtag: ", event);
      this.$refs.uploadFileComponent.deleteFile(event.deletedTag.value);
    },
    async getStructureValidations() {
      try {
        const response = await validations.getStructure(); 

        this.maxSizeBytesForDocuments = response.data.validations.officialIdentification.maxSizeBytes;
        this.maxSizeMegaBytesForDocuments = response.data.validations.officialIdentification.maxSizeMegaBytes;
        this.documentsAllowed = response.data.validations.officialIdentification.documentsAllowed;
        this.maxFiles = response.data.validations.officialIdentification.maxFiles;
        this.minFiles = response.data.validations.officialIdentification.minFiles;
      } catch (error) {
        console.error(error);
      }
    }
  },

  watch: {
    identification_number: function(value){

      this.$emit('identification-register-data', {
        files: this.files,
        identification_number: this.v$.identification_number.$model,
        passport_number: this.v$.passport_number.$model,
        document_type: this.v$.document_type.$model
      });
    },
    passport_number: function(value){

      this.$emit('identification-register-data', {
        files: this.files,
        identification_number: this.v$.identification_number.$model,
        passport_number: this.v$.passport_number.$model,
        document_type: this.v$.document_type.$model
      });
    },
    document_type : function(value){
      console.log("🚀 ~ file: IdentificationRegister.vue:245 ~ value", value)
      this.$emit('identification-register-data', {
        files: this.files,
        identification_number: this.v$.identification_number.$model,
        passport_number: this.v$.passport_number.$model,
        document_type: this.v$.document_type.$model
      });
    }
  },
  
  components: {
    UploadFile,
    TagsInput,
    Tags
  }
}
</script>