<template>
  <div>
    <div class="tag-container">
      <div class="tags">
        <div class="tag" :id="`tag_detail_file_${i}`" v-for="(tag, i) in tags" :key="i">
          <div>
            <span>{{tag.text}}</span>
          </div>
          <div style="margin-left:6px;">
            <img src="@/assets/close.svg" :id="`tag_delete_file_${i}`" @click="confirm(tag)" style="width:8px;cursor:pointer;" alt="">
          </div>
        </div>
        <input type="text"
          v-model="tag" 
          :disabled="disableInput"
          v-on:keyup.enter="addTag(tag)"
          v-on:blur="addTag(tag)" />
      </div>
    </div>
  </div>
</template>

<script>
import SwalAlertHelper from '@/helpers/swal-alert'
import Swal from "sweetalert2";
import('@/assets/css/tags.css')

export default {
  data() {
    return {
      tag: ""
    }
  },
  props: {
    tags: {
      type: Array,
      default: []
    },
    disableInput: {
      type: Boolean,
      default: false
    },
    confirmDelete: {
      type: Boolean,
      default: false
    }
  },
  name: 'TagComponent',
  methods: {
    addTag(tag) {
      if (tag != "") {
        this.tags.push({value: tag, text: tag});
        this.tag = ""; 
      }
    },
    confirm(tag) {
      if (this.confirmDelete) {
        Swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">¿Desea eliminar el documento: <span style="font-weight:500;">${tag.text}</span>?</p>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "SI",
          confirmButtonColor: '#FEB72B',
          cancelButtonText: "NO",
          cancelButtonColor: '#D2D2D2',
        })
        .then((result) => {
          if (result.value) {
            this.deleteTag(tag);
          }
        })
        SwalAlertHelper.setIdAttributeToButtons('button_yes_deleted_file', 'button_no_deleted_file');
        SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_delete_confirm');
      } else {
        this.deleteTag(tag);
      }
    },
    deleteTag(tag) {
      if (this.tags.length > 0) {
        const index = this.tags.findIndex(t => t.value == tag.value);

        if (index != -1) {
          this.tags.splice(index, 1)
        }
      }
      this.$emit('deleted-file', {
        tags: this.tags,
        deletedTag: tag
      });
    }
  }
}
</script>