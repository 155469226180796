<template>
  <div>

    <!-- Title -->
    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="">
        </div>
      </div>
      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Vivienda</label>
        </div>
      </div>
    </div>

    <!-- Form -->
    <div class="mt-10 grid grid-cols-1 sm:grid-cols-3 w-full gap-10 md:ml-10" v-if="rulesLoaded">
      <div>
        <label
          class="label-input w-full block tracking-wide"
          for="select_type_housing">
          Tipo de vivienda:
        </label>
        <select
          id="select_type_housing"
          v-model.trim="v$.housingInfo.type.$model"
          :class="{
                'invalid-form': v$.housingInfo.type.$error,
                'valid-form': !v$.housingInfo.type.$invalid,
          }"
          class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 mt-2 focus:border-cherryColor">
          <option value="seleccionar" selected hidden>Seleccione un tipo de vivienda</option>
          <option :value="item.value" v-for="(item, i) in housingTypes" :key="i">{{item.text}}</option>
        </select>

        <div v-for="error of v$.housingInfo.type.$errors" :key="error.$message">
          <div class="errors">{{ error.$message }}</div>
        </div>
      </div>
      <div>
        <label
          class="label-input w-full block tracking-wide"
          for="select_start_residency">
          Inicio de Residencia:
        </label>
        <Date v-on:get-complete-date-selected="getResponseDate($event)"
          idYear="select_start_residency_year"
          idMonth="select_start_residency_month"
          idDay="select_start_residency_day"
          :isRemaining="false"
          :classInput="v$.housingInfo.startOfResidency.$error ? 'invalid-form flex shadow-md border-2 rounded-lg px-2 py-2 w-full mt-2' : ' flex shadow-md border-2 rounded-lg px-2 py-2 w-full mt-2 valid-form'"
          class="w-full" />

        <div v-for="error of v$.housingInfo.startOfResidency.$errors" :key="error.$message">
          <div class="errors">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Date from '@/components/Forms/Selects/Date';
import("@/assets/css/referredperson.css");

import useVuelidate from '@vuelidate/core';
import { requiredIf, helpers } from '@vuelidate/validators';
import moment from "moment";

export default {
  name: "HousingInfo",

  props: {
    rulesValidations: {
      type: Object,
      default: () => {}
    },
    rulesLoaded: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      housingTypes: [
        {value: 'propia', text: 'Propia'},
        {value: 'rentada', text: 'Rentada'},
        {value: 'pagandola', text: 'Pagandola'},
        {value: 'con familiares', text: 'Con Familiares'},
        {value: 'prestada', text: 'Prestada'},
      ],
      housingInfo: {
        type: 'seleccionar',
        startOfResidency: ''
      },
    }
  },
  
  setup () {
    return { v$: useVuelidate() }
  },
  
  validations() {
    return {
      housingInfo: {
        type: {
          required: helpers.withMessage(`El campo es requerido` , requiredIf(function() {
            return this.rulesValidations.housingInfo.type.required;
          })),
          isUnique: helpers.withMessage(`El campo es requerido` ,function(value) {
            if (this.rulesValidations.housingInfo.type.required && value == 'seleccionar')
              return false;
            return true;
          }),
          $autoDirty: true
        },
        startOfResidency: {
          required: helpers.withMessage(`El campo es requerido` , requiredIf(function() {
            return this.rulesValidations.housingInfo.startOfResidency.required;
          })),
          dateLessThanOrEqualToActualDate: helpers.withMessage(`La fecha debe ser menor o igual a fecha actual`,function(value) {
            return moment(value).isBefore(moment())
          }),
          $autoDirty: true
        }
      }
    }
  },
  
  watch: {
    housingInfo: {
      handler(data) {
        this.$emit('housing-info-data', data);
      },
      deep: true
    }
  },

  methods: {
    getResponseDate(date) {
      this.housingInfo.startOfResidency = date;
    },
    touch(){
      this.v$.$validate();
      return this.v$.$error;
    },
  },

  components: {
    Date
  },
}
</script>