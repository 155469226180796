<template>
  <div>
    <div>
      <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="">
        </div>
      </div>
      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Contacto</label>
        </div>
      </div>
    </div>
      <div class="md:ml-10"  v-if="rulesLoaded">
        <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10">
          <div>
            <label class="label-input w-full block tracking-wide mb-2" to="input_phone_contact">Teléfono:</label>
            <!-- <input type="text" v-model.trim="v$.contact_info_attributes.phone.$model"> -->
            <input
              type="text"
              id="input_phone_contact"
              name="input_phone_contact"
              style="color: #3b3b3b"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              :class="{
                'input-invalid-form': v$.contact_info_attributes.phone.$error,
                'valid-form': v$.contact_info_attributes.phone.$model.length==12,
              }"
              v-model.trim="v$.contact_info_attributes.phone.$model"
              v-maska="'### ###-####'"
            />

            <div v-for="error of v$.contact_info_attributes.phone.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
           
          </div>
          <div class="mt-10 sm:mt-0">
            <label class="label-input w-full block tracking-wide mb-2" to="input_cellphone_contact">Celular:</label>
            <input
              type="text"
              id="input_cellphone_contact"
              name="input_cellphone_contact"
              style="color: #3b3b3b"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              :class="{
                'input-invalid-form': v$.contact_info_attributes.cellPhone.$error,
                'valid-form': !v$.contact_info_attributes.cellPhone.$invalid,
              }"
              v-model.trim="v$.contact_info_attributes.cellPhone.$model"
              v-maska="'### ###-####'"
            />

            <div v-for="error of v$.contact_info_attributes.cellPhone.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mt-10 sm:mt-0">
            <label class="label-input w-full block tracking-wide mb-2" to="input_email_contact">Email:</label>
            <input
              type="text"
              id="input_email_contact"
              name="input_email_contact"
              style="color: #3b3b3b"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              :class="{
                'input-invalid-form': v$.contact_info_attributes.email.$error,
                'valid-form': !v$.contact_info_attributes.email.$invalid,
              }"
              v-model.trim="v$.contact_info_attributes.email.$model"
            />

            <div v-for="error of v$.contact_info_attributes.email.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { email, requiredIf, minLength, maxLength, helpers } from '@vuelidate/validators';
import { get } from 'axios';
export default {
  name: "AssistedContactComponent",
  setup () {
    const isUnique = async (value) => {
      if((new RegExp("^[^@]+@[^@]+\.[a-zA-Z]{2,}$").test(value))){
        try {
          return await get(process.env.VUE_APP_CCAPI + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/email_with_account`, {
            params:{
              token_auth:sessionStorage.getItem("login"),
              email: value
            }
          }, { 'Content-Type': 'application/json' })
          .then(res => {
              
            return !res.data.response.email_with_account;
          });
        } catch (error) {
          return false;
        }
      }else {
        return true;
      }
    }
    return { v$: useVuelidate(), isUnique }
  },
  data() {
    return {
      row:1,
      ccapi: process.env.VUE_APP_CCAPI,
      contact_info_attributes:{
        phone: "",
        cellPhone: "",
        email: ""
      },
      tokenAuth:""
    };
  },
  props: {
    rulesValidations: {
      type: Object,
      default: () => {}
    },
    rulesLoaded: {
      type: Boolean,
      default: false
    },
  },
  
  validations() {
    return{
      contact_info_attributes:{
        phone: {
          required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.contact.phone.required})),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.contact.phone.minLength} caracteres` , function(value){
            if(value==""){
              return true
            }

            value = value.toString().replace("-", "");
            value = value.toString().replace(" ", "");
            
            if(value.toString().length < this.rulesValidations.contact.phone.minLength){
              return false
            }

            return true
          }),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.contact.phone.maxLength} caracteres` , function(value){
            value = value.toString().replace("-", "");
            value = value.toString().replace(" ", "");

            if(value.toString().length > this.rulesValidations.contact.phone.maxLength){
              return false
            }

            return true
          })
        },
        cellPhone: {
          required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.contact.cellPhone.required})),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.contact.cellPhone.minLength} caracteres` , function(value){
            value = value.toString().replace("-", "");
            value = value.toString().replace(" ", "");

            if(this.rulesValidations.contact.cellPhone.required && value.toString().length < this.rulesValidations.contact.cellPhone.minLength){
              return false
            }

            return true
          }),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.contact.cellPhone.maxLength} caracteres` , function(value){
            value = value.toString().replace("-", "");
            value = value.toString().replace(" ", "");

            if(value.toString().length > this.rulesValidations.contact.cellPhone.maxLength){
              return false
            }

            return true
          }),
          is_exist: helpers.withMessage("El Teléfono no puede ser el mismo que el Celular.", function () {
            if(this.contact_info_attributes.phone == "")
            {
              return true;
            }
            if(this.contact_info_attributes.phone.length < 12)
            {
              return true;
            }
            return this.contact_info_attributes.phone != this.contact_info_attributes.cellPhone
          })
        },
        email: {
          email: helpers.withMessage(`El valor no es una dirección de correo electrónico válida` , email), 
          required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return this.rulesValidations.contact.email.required})),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.contact.email.minLength} caracteres` , minLength(this.rulesValidations.contact.email.minLength)),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.contact.email.maxLength} caracteres` , maxLength(this.rulesValidations.contact.email.maxLength)),
          isUnique: helpers.withMessage(`El correo ya se encuentra registrado`, helpers.withAsync(this.isUnique)),
          $autoDirty: true 
        },
      }
    }
  },
  mounted(){
    this.tokenAuth = sessionStorage.getItem("login");
  },
  watch:{
    contact_info_attributes:{
      handler(data){
        this.$emit("contact_info_attributes",data)
      },
      deep:true
    }
  },
  methods:{
    touch(){
      this.v$.$validate();
      return this.v$.$error;
    },
   
  },
};
</script>