<template>
    <html>
      <body class="" style="background: #FFF">
        <Header />
        <MenuLateralMobile />
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="col-span-6" style="background:#FFF;padding:20px;">
              <AssistedEnrollmentStep1/>
            </div>
        </div>
      </body>
    </html>
</template>


<script>
import AssistedEnrollmentStep1 from '@/components/Administrative/AssistedEnrollment/FONCABSA/AssistedEnrollmentStep1.vue';
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
export default {
  name: 'AssistedEnrollmentStep1View',
  components: {
    AssistedEnrollmentStep1,
    Header,
    MenuLateralMobile,
    MenuLateral
  }
}
</script>
