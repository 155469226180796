<template>
  <div>
    <div class="flex">
      <div style="display: table">
        <div style="display: table-cell; vertical-align: middle">
          <img
            src="@/assets/CapturaAsistida/triangle.svg"
            style="width: 30px"
            alt=""
          />
        </div>
      </div>
      <div style="display: table; margin-left: 10px">
        <div style="display: table-cell; vertical-align: middle">
          <label class="sub-title">Datos de Socio</label>
        </div>
      </div>
    </div>
    <div v-if="rulesLoaded" class="md:ml-10">
      <div class="mt-10">
        <div class="grid md:grid-cols-3 grid-cols-1 gap-10">
          <div>
            <label
              class="label-input w-full block tracking-wide mb-2"
              for="input_RFC_personal_info"
            >
              R.F.C:
            </label>
            <input
              v-model.trim="v$.personalInfo.rfc.$model"
              v-maska="'AAAA######XXX'"
              id="input_RFC_personal_info"
              type="text"
              :minlength="rulesValidations.personalInfo.rfc.maxLength"
              :maxlength="rulesValidations.personalInfo.rfc.maxLength"
              :class="{
                'invalid-form': v$.personalInfo.rfc.$error,
                'valid-form': !v$.personalInfo.rfc.$invalid,
              }"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2 uppercase"
              @blur="validateStrucDatauserRFC()"
            />
            
            <div v-for="error of v$.personalInfo.rfc.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          <div>
            <label
              class="label-input w-full block tracking-wide mb-2"
              for="input_CURP_personal_info"
            >
              C.U.R.P:
            </label>
            <input
              v-model.trim="v$.personalInfo.curp.$model"
              v-maska="'AAAA######AAAAAAXX'"
              id="input_CURP_personal_info"
              type="text"
              :onKeyPress="`if(this.value.length==${rulesValidations.personalInfo.curp.maxLength}) return false;`"
              :class="{
                'invalid-form': v$.personalInfo.curp.$error,
                'valid-form': !v$.personalInfo.curp.$invalid,
              }"
              @blur="validatMatchCurpRfc(),validateStrucDatauserRFC()"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2 uppercase"
            />
            
            <div v-show="!isCurpValid">
              <div class="text-blue-500">Los primeros 10 caracteres de la CURP no coinciden con los primeros 10 caracteres del RFC</div>
            </div>
            <div v-for="error of v$.personalInfo.curp.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          <div class="w-full">
            <label class="label-input w-full block tracking-wide mb-2" for="input_zip_code_working_information" >
              Fecha de nacimiento:
            </label>
            
            <div :class="{ 'invalid-form': v$.personalInfo.dateBirth.$error, 'valid-form': !v$.personalInfo.dateBirth.$invalid,}"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2"
              id="input_personal_dateBirth"
              > {{ personalInfo.dateBirth }}</div>
            
            <div v-for="error of v$.personalInfo.dateBirth.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          <div>
            <label
              class="label-input w-full block tracking-wide mb-2"
              for="input_last_name_personal_info"
            >
              Apellido Paterno:
            </label>
            <input
              v-model.trim="v$.personalInfo.paternalName.$model"
              id="input_last_name_personal_info"
              type="text"
              :class="{
               'invalid-form': v$.personalInfo.paternalName.$error,
                'valid-form':
                  !v$.personalInfo.paternalName.$invalid,
              }" @blur="validateStrucDatauserRFC()"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2"
            />

            <div v-for="error of v$.personalInfo.paternalName.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          <div>
            <label
              class="label-input w-full block tracking-wide mb-2"
              for="input_mothers_last_name_personal_info"
            >
              Apellido Materno:
            </label>
            <input
              v-model.trim="v$.personalInfo.maternalName.$model"
              id="input_mothers_last_name_personal_info"
              type="text"
              :class="{
                'invalid-form': v$.personalInfo.maternalName.$error,
               'valid-form':
                  !v$.personalInfo.maternalName.$invalid,
              }" @blur="validateStrucDatauserRFC()"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2"
            />
            
            <div v-for="error of v$.personalInfo.maternalName.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          <div>
            <label
              class="label-input w-full block tracking-wide mb-2"
              for="input_first_name_personal_info"
            >
              Primer Nombre:
            </label>
            <input
              v-model.trim="v$.personalInfo.firstName.$model"
              id="input_first_name_personal_info"
              type="text"
              @blur="validateStrucDatauserRFC()"
              :class="{
                  'invalid-form': v$.personalInfo.firstName.$error,
               'valid-form':
                  !v$.personalInfo.firstName.$invalid,
              }"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2"
            />

            <div v-for="error of v$.personalInfo.firstName.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          <div>
            <label
              class="label-input w-full block tracking-wide mb-2"
              for="input_second_name_personal_info"
            >
              Segundo Nombre:
            </label>
            <input
              v-model.trim="v$.personalInfo.secondName.$model"
              id="input_second_name_personal_info"
              type="text"
              :class="{
                 'invalid-form': v$.personalInfo.secondName.$error,
                  'valid-form':
                  !v$.personalInfo.secondName.$invalid,
              }"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2"
              @blur="validateStrucDatauserRFC()"
            />

            <div v-for="error of v$.personalInfo.secondName.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
          <div>
            <ChooseButton
              :buttons="buttonsGender"
              v-on:choose-button="getValueChooseButton($event)"
              label="Género"
            />

            <div v-for="error of v$.personalInfo.gender.$errors" :key="error.$message">
              <div class="errors">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { minLength, maxLength, requiredIf, helpers } from '@vuelidate/validators';

import User from "@/classes/User";
import texts from "@/helpers/texts";
import { getRangeOfYears } from "@/helpers/dates";

import ChooseButton from "@/components/Forms/Buttons/ChooseButton.vue";
import DateComponent from "@/components/Forms/Selects/Date";
import Swal from "sweetalert2";
import("@/assets/css/referredperson.css");
import moment from "moment";

export default {
  name: "PersonalInfo",

  props: {
    rulesValidations: {
      type: Object,
      default: () => {},
    },
    rulesLoaded: {
      type: Boolean,
      default: false,
    },
    spouseRfc: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isCurpValid: true,
      isRfcValid: true,
      personalInfo: {
        rfc: "",
        curp: "",
        dateBirth: "",
        paternalName: "",
        maternalName: "",
        firstName: "",
        secondName: "",
        gender: "",
      },
      years: [],
      birthdate_day_member: "",
      birthdate_month_member: "",
      birthdate_year_member: "",
      refreshDate: "",
      buttonsGender: [
        {
          id: "button_gender_male_personal_info",
          value: "masculino",
          text: "Hombre",
        },
        {
          id: "button_gender_female_personal_info",
          value: "femenino",
          text: "Mujer",
        },
      ],
      userObjectClass: null,
      isRequiredRfc: true,
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      personalInfo: {
        rfc: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function () {
            return this.rulesValidations.personalInfo.rfc.required;
          })),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.personalInfo.rfc.minLength} caracteres`, minLength(
            this.rulesValidations.personalInfo.rfc.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.personalInfo.rfc.minLength} caracteres`, maxLength(
            this.rulesValidations.personalInfo.rfc.maxLength
          )),
          structureIsValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , function (value) {
            
            let isValid = true;
            if(value != ""){
              const validate = this.validateRfcStructure(value.toString().toUpperCase());
              isValid = validate.status;
            }else{
              isValid = true;
            }
            
            return isValid;
          }),
          alreadyExists: helpers.withMessage("El RFC capturado ya existe en base de datos. Favor de capturar otro" , helpers.withAsync(async function (value) {
            const rfcIsValid = this.validateRfcStructure(value.toString().toUpperCase());
            if(rfcIsValid.status && value != "") {
              return await this.validateRfcAlreadyExists(value);
            }
            return true;
          })),
          formatValidator:
            async function (value) {
          },
          isValid: helpers.withMessage("Los datos de nombre no coinciden con el RFC" , function(){
            if(!this.isRequiredRfc){
              return true;
            }

            return this.isRfcValid;
          }),
          isDinstinct: helpers.withMessage("El RFC del socio no puede ser el mismo que el del cónyuge" , function(value){
            if(value == "" && this.spouseRfc == "")
              return true;
            return value == this.spouseRfc? false: true;
          })
        },
        curp: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function () {
            return this.rulesValidations.personalInfo.curp.required;
          })),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.personalInfo.curp.minLength} caracteres`, minLength(
            this.rulesValidations.personalInfo.curp.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.personalInfo.curp.maxLength} caracteres`, maxLength(
            this.rulesValidations.personalInfo.curp.maxLength
          )),
          structureIsValid: helpers.withMessage("El formato del CURP no es correcto. Ejemplo de formato correcto: LOMP8206281HSRTZR02" , function (value) {
            return this.personalInfo.curp == ""? true : this.validateCurpStructure(value.toUpperCase());
          })
        },
        paternalName: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function () {
            return this.personalInfo.maternalName == "" ? true : false;
          })),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.personalInfo.paternalName.minLength} caracteres`,  minLength(
            this.rulesValidations.personalInfo.paternalName.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.personalInfo.paternalName.maxLength} caracteres`,  maxLength(
            this.rulesValidations.personalInfo.paternalName.maxLength
          )),
        },
        maternalName: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function () {
            return this.personalInfo.paternalName == "" ? true : false;
          })),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.personalInfo.maternalName.minLength} caracteres`,  minLength(
            this.rulesValidations.personalInfo.maternalName.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.personalInfo.maternalName.maxLength} caracteres`,  maxLength(
            this.rulesValidations.personalInfo.maternalName.maxLength
          )),
        },
        firstName: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function () {
            return this.rulesValidations.personalInfo.firstName.required;
          })),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.personalInfo.firstName.minLength} caracteres`, minLength(
            this.rulesValidations.personalInfo.firstName.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.personalInfo.firstName.maxLength} caracteres`,  maxLength(
            this.rulesValidations.personalInfo.firstName.maxLength
          )),
        },
        secondName: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function () {
            return this.rulesValidations.personalInfo.secondName.required;
          })),
          minLength: helpers.withMessage(`El campo debe contener mínimo ${this.rulesValidations.personalInfo.secondName.minLength} caracteres`, minLength(
            this.rulesValidations.personalInfo.secondName.minLength
          )),
          maxLength: helpers.withMessage(`El campo debe contener máximo ${this.rulesValidations.personalInfo.secondName.maxLength} caracteres`,  maxLength(
            this.rulesValidations.personalInfo.secondName.maxLength
          ))
        },
        dateBirth: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function () {
            return this.rulesValidations.personalInfo.dateBirth.required;
          })),
        },
        gender: {
          required: helpers.withMessage("El campo es requerido" , requiredIf(function () {
            return this.rulesValidations.personalInfo.gender.required;
          })),
        },
      },
    };
  },

  methods: {
    validatMatchCurpRfc(){
      const curp = this.personalInfo.curp;
     
      if (curp.length >= 10) {
        if (this.validateCurpStructure(curp.toUpperCase())) {
          if (User.matchesCurpWithRfc(curp, this.personalInfo.rfc)) {
            this.isCurpValid = true;
            return;
          }
          this.isCurpValid = false;
          return;
        }
      }
      this.isCurpValid = true;
    },
    validateHasOnlyLetters(value, modelInput) {
      if (value != "") {
        const hasOnlyLetters =
          this.userObjectClass.validateHasOnlyLetters(value);
        if (!hasOnlyLetters) {
          this.personalInfo[modelInput] = texts.removeTextByRegex(
            value,
            /[^a-zA-ZÀ-ÿ@\s-]+/
          );
        }
      }
    },
    validateHasOnlyLettersAndNumbers(value, modelInput) {
      if (value != "") {
        const hasOnlyLetters =
          this.userObjectClass.validateHasOnlyLettersAndNumbers(value);
        if (!hasOnlyLetters) {
          this.personalInfo[modelInput] = texts.removeTextByRegex(
            value,
            /[^a-zA-ZÀ-ÿ0-9@\s]+/
          );
        }
      }
    },
    getValueChooseButton(event) {
      this.personalInfo.gender = event;
    },
    getYears() {
      this.years = getRangeOfYears(100);
    },
    validateRfcStructure(rfc) {
      let rfc_pattern =
        "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
      const coincidences = rfc.match(rfc_pattern);
     
      const data = [];
      if(coincidences!=null){
        coincidences.forEach(element => {
          if(typeof element != 'undefined'){
            data.push(element);
          }
        });
      }
      return coincidences == null ? {'status': false, 'coincidences': data} : {'status': true, 'coincidences': data};
    },
    validateCurpStructure(curp) {
      let curp_pattern =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
      const coincidences = curp.match(curp_pattern);
      return coincidences == null ? false : true;
    },
    async validateRfcAlreadyExists(rfc) {
        return await this.userObjectClass.rfcAlreadyExists(
          sessionStorage.getItem("login"), rfc.toString().toUpperCase()
        ).then(res =>{
          return !res.data.response.is_already_registered;
        }).catch(err =>{
          console.log(err)
          return false;
        });
    },
    validateCompleteRfc() {
      try {
        let seconName=this.validateNames(this.personalInfo.paternalName, this.personalInfo.maternalName)
        let names=this.validateNames(this.personalInfo.firstName, this.personalInfo.seconName)
        if(this.personalInfo.rfc!="" && this.personalInfo.curp!="" && this.personalInfo.dateBirth!="" && names && seconName ){
          return this.userObjectClass
            .rfcFormatIsValid({
              rfc: this.personalInfo.rfc,
              dateBirth: this.personalInfo.dateBirth,
              firstName: this.personalInfo.firstName,
              secondName: this.personalInfo.secondName,
              paternalName: this.personalInfo.paternalName,
              maternalName: this.personalInfo.maternalName,
            })
            .then((res) => {
              return res.data.response.has_errors;
            })
            .catch((err) => {
              return err.data.response.has_errors;
            });
        }
      } catch (error) {
        return false;
      }
    },
    getResponseDate(event) {
      this.personalInfo.dateBirth = event;
    },
    touch(){
      this.v$.$validate();
      return this.v$.$error;
    },  
    exceptionMessageRFC() {
      Swal.fire({
        title:
          "Los datos de nombre o fecha de nacimiento no coinciden con el RFC",
        html: `¿Es correcto y desea continuar con el registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isRequiredRfc = false;
        } else {
          this.isRequiredRfc = true;
        }
      });
    },
    getBirthdayByRfc(value){
      var m = value.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
      var year = parseInt(m[1],10)+1900;
      if( year < 1925 ) year += 100;
      var month = parseInt(m[2], 10)-1;
      var day = parseInt(m[3], 10);
      return `${year}-${month}-${day}`;
    },
    validateNames(firstName,secondName){
      if(firstName!="" || secondName!="") {
         return true
      }else{
        return false
      }
    },
    async validateStrucDatauserRFC(){
      let seconName=this.validateNames(this.personalInfo.paternalName, this.personalInfo.maternalName)
      let names=this.validateNames(this.personalInfo.firstName, this.personalInfo.secondName)
      if(this.personalInfo.rfc!="" && this.personalInfo.curp!="" && this.personalInfo.dateBirth!="" && names && seconName ){
        if (
              !this.rulesValidations.personalInfo.rfc.required ||
              this.personalInfo.rfc == "" ||
              !this.isRequiredRfc
            ) {
              return true;
            }

            if (
              this.personalInfo.rfc.toString().length < this.rulesValidations.personalInfo.rfc.minLength &&
              !this.v$.personalInfo.rfc.structureIsValid
            ) {
              return false;
            }
            
            const request = this.validateRfcStructure(this.personalInfo.rfc.toUpperCase());
            if (request.status) {
              
              const isValid = await new Promise((resolve, reject) => {
                this.validateCompleteRfc().then(res=>{
                  this.isRfcValid = !res;
                  resolve(!res);
                  return !res;
                }).catch(err=>{
                  this.isRfcValid = false;
                  reject(!err);
                  return false;
                });
              });

              console.log("El campo es valido",isValid)

              if (!isValid) {
                // Muestra mensaje de excepcion de rfc y nombre con apellido invalido
                this.exceptionMessageRFC();
              }
              
              return isValid;
            }
            return false;
      }
    }
  },

  watch: {
    "personalInfo.rfc": function (val) {
      this.isRequiredRfc = true;

      if (val.length < this.rulesValidations.personalInfo.rfc.minLength) {
        this.personalInfo.dateOfBirth = "";
      }

      if (val.length >= this.rulesValidations.personalInfo.rfc.minLength) {
        const date = new Date()
        const yearPlusOne = date.setFullYear(date.getFullYear() +1 )
        const yearPlusOneSub = new Date(yearPlusOne).getFullYear().toString().substring(2,4);
            
        const extractFullDate = val.substring(4, 10);
        const yearRFC = extractFullDate.substring(0, 2);
        const getYearFormat = yearPlusOneSub > yearRFC ? `20${yearRFC}`: `19${yearRFC}`

                
        const month = extractFullDate.substring(2, 4);
        const day = extractFullDate.substring(4, 6);

        const formatYear = `${day}/${month}/${getYearFormat}`;
        this.personalInfo.dateBirth = formatYear
        // this.refreshDate = formatYear;
        this.personalInfo.curp = this.personalInfo.rfc.substr(0, 10);

        // var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        // let date = (this.personalInfo.rfc!="")? this.getBirthdayByRfc(this.personalInfo.rfc).toString():'';
        // this.birthdate_day_member = parseInt(moment(date).format('DD'));
        // this.birthdate_day_member = this.birthdate_day_member<10? `0${this.birthdate_day_member}` : `${this.birthdate_day_member}`;

        // this.birthdate_month_member = moment(date).format('MM').toString();
        // this.birthdate_month_member = months[parseInt(this.birthdate_month_member)];
        // this.birthdate_year_member = moment(date).format('YYYY').toString();
        
        // this.personalInfo.dateBirth = `${this.birthdate_year_member}-${parseInt(months.indexOf(this.birthdate_month_member))+1}-${ this.birthdate_day_member }`;
        // this.refreshDate = this.personalInfo.dateBirth;
      }
    },
    "personalInfo.curp": function (val) {
      this.validateHasOnlyLettersAndNumbers(val, "curp");
    },
    "personalInfo.paternalName": function (val) {
      this.isRequiredRfc = true;
      this.validateHasOnlyLetters(val, "paternalName");
    },
    "personalInfo.maternalName": function (val) {
      this.isRequiredRfc = true;
      this.validateHasOnlyLetters(val, "maternalName");
    },
    "personalInfo.firstName": function (val) {
      this.isRequiredRfc = true;
      this.validateHasOnlyLetters(val, "firstName");
    },
    "personalInfo.secondName": function (val) {
      this.validateHasOnlyLetters(val, "secondName");
    },
    personalInfo: {
      handler(data) {
        this.$emit("personal-info-data", data);
      },
      deep: true,
    },
  },

  beforeMount() {
    this.userObjectClass = new User();
    this.getYears();
  },

  components: {
    ChooseButton,
    DateComponent,
  },
};
</script>
